// price top section
.price_top_section {
	padding: 50px 0px;
	height: 100vh;
	background-color: $secondary_bg;
	position: relative;
	z-index: -1;
	&::before {
		position: absolute;
		content: '';
		left: -20px;
		top: 20px;
		width: 100%;
		height: 100px;
		background-image: url('/images/bg/hero-vector-bg-dot.png');
		background-repeat: no-repeat;
		z-index: -1;
		background-position: left;
		background-size: contain;
	}
	&::after {
		position: absolute;
		content: '';
		right: 0px;
		top: 0px;
		width: 45%;
		height: 100%;
		background-image: url('/images/page/price/vector-1.png');
		background-repeat: no-repeat;
		z-index: -1;
		background-size: cover;
	}
	.section_title {
		max-width: 700px;
		text-align: center;
		margin: auto;
		font-size: 36px;
		color: $home_title_color;
		font-weight: 600;
	}
}
// price section price plan part
.price_plan_section_wrapper {
	margin-top: -65vh;
	.price_plan_section {
		display: flex;
		align-items: center;
		justify-content: center;

		.price_plan_section_card {
			background-color: white;
			max-width: 400px;
			width: 100%;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			padding: 40px 0;
			border-radius: 10px;
			position: relative;

			&:nth-of-type(2) {
				border: 2px solid $home_secondary_color;
				transform: scale(1.1);
				z-index: 1;
				.card_plan_list_wrapper {
					background-color: $secondary_bg;
				}
			}

			.price_offer_tag_wrapper {
				position: absolute;
				top: -15px;
				left: 50%;
				transform: translateX(-50%);
				padding: 3px 10px;
				box-shadow: rgba(64, 164, 189, 0.5) 0px 1px 4px;
				border-radius: 15px;
				display: inline-block;
				background-color: white;
				width: max-content;
				.price_offer_tag {
					display: flex;
					align-items: center;
					gap: 4px;
					font-size: 12px;
					font-weight: 600;
					text-transform: capitalize;
					color: $title-color;
				}
			}

			.price_plan_section_card_title {
				text-align: center;
				display: flex;
				justify-content: center;
				.card_title {
					margin: 0;
					text-transform: uppercase;
					font-size: 24px;

					color: $price-title-color;
					font-weight: 600;
				}
			}
			.price_plan_body {
				margin: 10px 0;
				.price_plan_section_card_price {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.card_price_wrapper {
						display: flex;
						align-items: center;
						gap: 5px;

						color: $home_title_color;
						.card_price_symbol {
							font-size: 32px;
							font-weight: 700;
							margin: -5px 0 0 0;
						}
						.card_price {
							font-size: 46px;
							font-weight: 700;
							margin: 0 !important;
						}
						.card_price_month {
							font-size: 16px;
							font-weight: 500;
						}
					}
				}
				.card_plan_list_wrapper {
					margin-top: 20px;
					padding: 20px 60px;
					.card_plan_list {
						margin: 5px 0;
						padding: 5px 0;
						display: flex;
						align-items: center;
						font-size: 14px;

						gap: 10px;
						position: relative;
						.item_list_icon {
							color: $home_secondary_color;
						}
						.item_icon_false {
							color: #ee3232;
						}
						.item_list_title {
							margin: 0;
						}
						&::after {
							position: absolute;
							content: '';
							width: 100%;
							height: 1px;
							background: rgb(226, 225, 225);
							bottom: -3px;
						}
						&:last-child::after {
							display: none;
						}
					}
				}
			}
			.price_plan_button {
				display: block;
				width: 150px;
				height: 38px;
				border: 2px solid $home_secondary_color;
				margin: auto;
				text-align: center;
				text-transform: capitalize;
				transition: 0.5s;
				background-size: 200% auto;
				color: $home_title_color;
				border-radius: 8px;
				display: block;
				font-size: 16px;
				font-weight: 500;
				&:hover {
					background-image: $home_gradient_primary_button;
					color: #fff;
					background-position: right center;
					text-decoration: none;
					border: 1px solid transparent;
				}
			}
		}
	}
	// price compare section
	.price_compare_section {
		padding-top: 120px;
		// background-color: red;
		.price_compare_section_title {
			max-width: 700px;
			text-align: center;
			margin: auto;
			font-size: 36px;
			color: $home_title_color;
			font-weight: 600;
		}
		.price_compare_table_wrapper {
			padding-top: 80px;
			overflow-x: scroll;
			.price_compare_table {
				width: 100%;
				.price_compare_tablehead,
				.price_compare_tablebody {
					text-align: center;
				}
				.price_compare_tablehead {
					tr {
						th {
							min-width: 120px;
							padding: 20px 10px;
							background-color: $home_secondary_color;
							color: white;
							font-size: 18px;
							text-transform: capitalize;
							font-weight: 600;
							p {
								font-size: 18px;
								margin: 0;
							}

							// &:nth-child(2) {
							// 	background-color: #ffd928;
							// }
							// &:nth-child(3) {
							// 	background-color: #25b7ff;
							// }
							// &:nth-child(4) {
							// 	background-color: #32303f;
							// }
						}
					}
				}
				.price_compare_tablebody {
					tr {
						border-bottom: 1px solid $devider-color;
						td {
							font-size: 16px;
							font-weight: 600;
							padding: 20px 10px;

							&:first-child {
								min-width: 250px;
								font-size: 16px;
								color: $home_paragraph_color;
							}
							&:last-child {
								border: none;
							}
							.price_compare_feature_wrapper {
								display: flex;
								align-items: center;
								gap: 10px;
							}
							.item_list_icon {
								color: $home_secondary_color;
							}
							.item_icon_true {
								font-size: 24px;
								color: $home_secondary_color;
							}
							.item_icon_false {
								font-size: 30px;
								color: #ee3232;
							}
							.item_title_icon {
								font-size: 18px;
								color: #ababab;
							}
						}
						&:nth-child(odd) {
							background: $secondary_bg;
						}
					}
				}
			}
		}
	}
}
// price footer section
.price_footer_section {
	margin: 50px 0;
	height: 100%;
	min-height: 330px;
	position: relative;
	background-image: url('/images/page/price/price-bg.png');
	background-repeat: no-repeat;
	z-index: 1;
	background-position: center;
	background-size: cover;
	&::after {
		position: absolute;
		content: '';
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: -1;
		background: rgba(0, 0, 0, 0.753);
		backdrop-filter: blur(1px);
	}
	&::before {
		position: absolute;
		content: '';
		right: -20px;
		bottom: 10px;
		width: 100%;
		height: 100px;
		background-image: url('/images/bg/hero-vector-bg-dot.png');
		background-repeat: no-repeat;
		background-position: right;
		background-size: contain;
	}
	.price_footer_card_wrapper {
		margin: auto;
		text-align: center;
		padding: 30px;
		h1 {
			color: #bbc2ee;
			font-size: 46px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
		p {
			color: #fff;
			margin: 0;
			font-family: Poppins;
			font-size: 30px;
			font-style: normal;
			font-weight: 400;
		}
		.price_footer_button_wrapper {
			margin: 40px 0 25px 0;
			display: flex;
			align-items: center;
			justify-content: center;

			gap: 20px;
			.price_button {
				background-image: $home_gradient_primary_button;
				text-align: center;
				text-transform: capitalize;
				transition: 0.5s;
				background-size: 200% auto;
				color: white;
				border-radius: 8px;
				display: block;
				font-size: 18px;
				font-weight: 400;
				width: 180px;
				height: 52px;

				&:hover {
					color: #fff;
					background-position: right center;
					text-decoration: none;
				}
			}
			.price_button_border {
				background: transparent;
				text-align: center;
				text-transform: capitalize;
				transition: ease all 0.5s;
				background-size: 200% auto;
				color: white;
				display: block;
				font-size: 18px;
				font-weight: 400;
				width: 180px;
				height: 52px;
				position: relative;

				&::after {
					content: '';
					position: absolute;
					inset: 0;
					border-radius: 8px;
					padding: 2px;
					background: $home_gradient_border border-box;
					-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
					mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
					mask-composite: exclude;
				}
				&:hover {
					border-radius: 8px;
					background-image: $home_gradient_primary_button;
					border: none !important;
					background-position: right center;
					text-decoration: none;

					&.price_button_border::after {
						display: none;
					}
				}
			}
		}
		.price_footer_text_wrapper {
			margin-top: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;

			.text_wrapper {
				display: flex;
				align-items: center;
				gap: 5px;
				justify-content: center;
				.check_icon {
					color: white;
					font-size: 18px;
				}
				p {
					font-size: 18px;
					margin: 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.price_top_section {
		padding: 20px 0px;

		.section_title {
			max-width: 700px;
			font-size: 26px;
		}
	}
	.price_plan_section_wrapper {
		margin-top: -70vh;
		.price_plan_section {
			gap: 10px;
			flex-direction: column;

			.price_plan_section_card {
				&:nth-child(2) {
					border: none;
					transform: scale(1);
					z-index: 1;
					.card_plan_list_wrapper {
						background-color: inherit;
					}
				}

				.price_plan_section_card_title {
					.card_title {
						font-size: 22px;
					}
				}
				.price_plan_body {
					margin: 10px 0;
					.price_plan_section_card_price {
						.card_price_wrapper {
							.card_price_symbol {
								font-size: 28px;
							}
							.card_price {
								font-size: 32px;
							}
							.card_price_month {
								font-size: 14px;
							}
						}
					}
					.card_plan_list_wrapper {
						margin-top: 10px;
						padding: 20px;
					}
				}
			}
		}
		// price compare section
		.price_compare_section {
			margin-top: 50px;
			.price_compare_section_title {
				max-width: 100%;
				font-size: 26px;
			}
			.price_compare_table_wrapper {
				padding-top: 50px;
				overflow-x: scroll;
				.price_compare_table {
					width: 100%;
					.price_compare_tablehead,
					.price_compare_tablebody {
						text-align: center;
					}
					.price_compare_tablehead {
						tr {
							th {
								padding: 10px;

								font-size: 16px;

								p {
									font-size: 16px;
									margin: 0;
								}
							}
						}
					}
					.price_compare_tablebody {
						tr {
							td {
								font-size: 14px;
								font-weight: 600;
								padding: 10px;
								// min-width: 250px;
								border-bottom: 1px solid $devider-color;

								&:first-child {
									min-width: 250px;
									font-size: 14px;
									color: $home_paragraph_color;
								}
								&:last-child {
									border: none;
								}
								.price_compare_feature_wrapper {
									display: flex;
									align-items: center;
									gap: 10px;
								}
								.item_list_icon {
									color: $home_secondary_color;
								}
								.item_icon_true {
									font-size: 19px;
									color: $home_secondary_color;
								}
								.item_icon_false {
									font-size: 22px;
									color: #ee3232;
								}
								.item_title_icon {
									font-size: 14px;
									color: #ababab;
								}
							}
							&:nth-child(odd) {
								background: $secondary_bg;
							}
						}
					}
				}
			}
		}
	}
	.price_footer_section {
		margin: 50px 0;
		height: 100%;
		min-height: max-content;

		.price_footer_card_wrapper {
			margin: auto;
			text-align: center;
			padding: 30px;
			h1 {
				color: #bbc2ee;
				font-size: 30px;
				font-style: normal;
				font-weight: 600;
				// line-height: normal;
			}
			p {
				color: #fff;
				margin: 0;
				font-family: Poppins;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
			.price_footer_button_wrapper {
				margin: 30px 0 10px 0;

				gap: 10px;
				.price_button {
					font-size: 12px;
					width: max-content;
					height: max-content;
					padding: 8px 15px;
				}
				.price_button_border {
					font-size: 12px;
					width: max-content;
					height: max-content;
					padding: 8px 15px;
				}
			}
			.price_footer_text_wrapper {
				gap: 20px;

				.text_wrapper {
					gap: 5px;

					.check_icon {
						font-size: 14px;
					}
					p {
						font-size: 12px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1440px) {
	.price_top_section {
		padding: 50px 0px;
		height: 60vh;
		background-color: $secondary_bg;
		position: relative;
		z-index: -1;
	}
	.price_plan_section_wrapper {
		margin-top: -35vh;
	}
}

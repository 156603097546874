.billing_section {
	max-width: 1200px;
	margin: auto;
}
.billing_section .billing_tab_section {
	.custom_tabs {
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;

		.custom_tab {
			min-width: 120px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
			border: 1px solid $devider-color;
			text-align: center;
			border-radius: 5px;
			padding: 5px 10px;
			cursor: pointer;
			font-size: 14px;
			font-weight: 600;
			&.active {
				color: white;
				background-image: $gradient-primary;
				position: relative;
			}
		}
	}
}

.billing_content_section {
	.billing_details_wrapper {
		display: flex;
		gap: 20px;
		.billing_payment_section {
			flex: 1;
			.billing_payment_section_item {
				.billing_details_title {
					text-transform: capitalize;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 12px;
					h6 {
						font-size: 14px;
						margin: 0;
					}
					span {
						color: $title-color;
						font-size: 11px;
						cursor: pointer;
					}
				}
				.payment_period_card {
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
					border: 1px solid rgb(223, 223, 243);
					background-color: rgb(247, 243, 243);
					margin-top: 6px;
					padding: 20px;
					color: $title-color;
					border-radius: 5px;
					h6 {
						font-size: 18px;
						margin-bottom: 5px;
					}
					p {
						text-transform: capitalize;
						color: $light-text-color;
						font-size: 12px;
						margin: 0;
					}
				}
				.payment_details_card {
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
					border: 1px solid rgb(223, 223, 243);
					background-color: rgb(247, 243, 243);
					margin-top: 6px;
					padding: 20px 10px;
					display: flex;
					gap: 10px;
					border-radius: 5px;
					.payment_type_image {
						width: 120px;
						height: 80px;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
					}
					.payment_info {
						.account_number {
							color: $title-color;
							font-size: 21px;
						}
						.account_name {
							color: $title-color;
							font-size: 14px;
							margin: 0;
							text-transform: uppercase;
						}
						.account_exp {
							margin: 0;
							font-size: 12px;
							color: gray;
						}
					}
				}
			}
		}
		.billing_info_section {
			flex: 2;
			padding: 0 10px;
			h6 {
				font-size: 14px;
				margin: 0 10px 10px 10px;
			}
			.button_wrapper {
				margin-top: 10px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
	}
}

.price_plan_section {
	.price_plan_secton_title {
		h6 {
			margin: 0;
		}
		p {
			margin: 0;
			color: rgb(110, 108, 108);
		}
	}
	.price_plan_content {
		margin: 10px 0;
		padding: 5px;
		.price_plan_item_wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;
			flex-wrap: wrap;
			.price_plan_item {
				box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
				border: 1px solid #4e37b23b;
				min-width: 300px;
				min-height: 350px;
				text-align: center;
				padding: 20px 0px;
				border-radius: 10px;

				.plan_title {
					text-transform: uppercase;
					font-size: 12px;
					color: $price-title-color;
				}
				.price_plan_price_section {
					text-transform: capitalize;
					color: $price-title-color;
					h6 {
						font-size: 22px;
						margin: 0;
					}
					p {
						font-size: 12px;
					}
				}
				.plan_list_wrapper {
					padding: 10px;
					.plan_list_item {
						padding: 5px;
						display: flex;
						align-items: center;
						gap: 5px;
						font-size: 14px;
						color: $price-title-color;
						font-weight: 600;
						.item_list_title {
							margin: 0;
						}
						.item_list_icon {
							color: $title-color;
						}
						.item_icon_false {
							color: rgba(219, 89, 100, 0.795);
						}
					}
				}

				.price_button {
					padding: 3px 20px;
					color: $primary-color;
					text-transform: capitalize;
					font-weight: bold;
					border-radius: 15px;
					border: none;
					box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
					font-size: 12px;
					text-transform: capitalize;
					border: 1px solid $primary-color;
					cursor: pointer;

					&:hover {
						background: $gradient-primary;

						color: white;
					}
				}
				.price_button_text {
					font-size: 14px;
					border-radius: 15px;
					background: $gradient-secondary;
					padding: 5px 20px;
					color: white;
					width: 100%;
					text-transform: capitalize;
				}
			}
		}
	}
	.price_compare_section {
		margin-top: 50px;
		.price_compare_section_title {
			text-align: center;
			font-size: 30px;
			color: $gray-text-color;
		}
		.price_compare_table_wrapper {
			padding-top: 40px;
			overflow-x: scroll;
			.price_compare_table {
				width: 100%;
				.price_compare_tablehead,
				.price_compare_tablebody {
					text-align: center;
				}
				.price_compare_tablehead {
					tr {
						th {
							padding: 20px 10px;
							background-color: $sub-text-color;
							color: white;
							font-size: 18px;
							text-transform: capitalize;
							font-weight: 600;
							p {
								margin: 0;
							}
							span {
								font-weight: 500;
								font-size: 16px;
							}
							&:nth-child(1) {
								min-width: 250px;
								background-color: white;
								color: $sub-text-color;
							}
							// &:nth-child(2) {
							// 	background-color: #ffd928;
							// }
							// &:nth-child(3) {
							// 	background-color: #25b7ff;
							// }
							// &:nth-child(4) {
							// 	background-color: #32303f;
							// }
						}
					}
				}
				.price_compare_tablebody {
					tr {
						td {
							font-size: 16px;
							font-weight: 600;
							padding: 20px 10px;
							// min-width: 250px;
							border-bottom: 1px solid $devider-color;

							&:first-child {
								min-width: 250px;
								font-size: 14px;
								color: $price-title-color;
							}
							.price_compare_feature_wrapper {
								display: flex;
								align-items: center;
								gap: 8px;
							}
							.item_list_icon {
								color: $sub-text-color;
							}
							.item_icon_true {
								color: $sub-text-color;
							}
							.item_icon_false {
								color: rgba(219, 89, 100, 0.795);
							}
							&:nth-child(odd) {
								background: white;
							}
						}
					}
				}
			}
		}
	}
}

//checkout

.checkout_select_section {
	margin-top: 20px;
	.checkout_section_title{
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 15px;
		text-transform: capitalize;
	}
	.ant-radio-group {
		display: flex;
		align-items: center;
		gap: 5px;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.ant-radio-button-wrapper {
		height: max-content;
		border: none !important;
		border-radius: 0px !important;
		text-align: center;
		padding: 0;
		&::before {
			background-color: transparent !important;
		}
	}

	.ant-radio-button-wrapper-checked {
		background: $gradient-secondary !important;
		color: white !important;
		border-radius: 0px !important;
	}

	.checkout_card_wrapper {
		padding: 20px 10px;
		position: relative;
		border: 1px solid $devider-color;
		min-width: 170px;
		border-radius: 10px !important;
		overflow: hidden !important;
		&.active {
			.active_package {
				display: block;
			}
		}
		.active_package {
			display: none;
			position: absolute;
			top: 0;
			right: 5px;
			font-size: 10px;
			line-height: 20px;
			font-weight: 800;
		}
		.checkout_package_title {
			font-size: 22px;
			margin: 0;
		}
	}
}

.newsletter_section_wrapper {
	margin: 100px 0;
	height: 330px;
	background-color: #325aab;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&::before {
		position: absolute;
		content: '';
		// top: 0;
		bottom: 0;
		// right: 0;
		left: 0;
		width: 58%;
		height: 100%;
		background-image: url('/images/bg/subscribe-bg-2.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	&::after {
		position: absolute;
		content: '';
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('/images/bg/subscribe-bg-1.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}
.newsletter_section_wrapper .newsletter_section {
	max-width: 600px;
	margin: auto;
	padding: 50px 10px;

	color: white;
}
.newsletter_section_wrapper .newsletter_section .newsletter_info {
	text-align: center;
	.newsletter_title {
		font-size: 30px;
		font-weight: 600;
		text-transform: capitalize;
	}
	.newsletter_subtitle {
		margin: 0 auto;
		font-size: 12px;
		color: rgba(255, 255, 255, 0.877);
		max-width: 300px;
		line-height: 18px;
	}
}
.newsletter_section_wrapper .newsletter_section .newsletter_info .newsletter_subscription {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0;

	.add-email {
		width: 360px;
		height: 42px;
		border: none;
		outline: none;
		padding: 20px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.945);
		&::placeholder {
			font-size: 13px;
			color: #9f9e9e;
		}
	}
	.submit_email {
		height: 42px;
		border: none;
		font-size: 14px;
		background-color: #1a99b4;
		padding: 0 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 1;
	}
}
@media only screen and (max-width: 768px) {
	.newsletter_section_wrapper {
		margin: 50px 0;
		height: 280px;
	}

	.newsletter_section_wrapper .newsletter_section .newsletter_info {
		.newsletter_title {
			font-size: 26px !important;
		}
		.newsletter_subtitle {
			font-size: 12px;
		}
	}
	.newsletter_section_wrapper .newsletter_section .newsletter_info .newsletter_subscription {
		.add-email {
			width: 100%;
			padding: 10px;
		}
		.submit_email {
			font-size: 12px;
			padding: 0 10px;
		}
	}
}

.invoice_section_wrapper {
	padding: 40px 0;
	background-color: #efefef;

	.invoice_section_container {
		max-width: 920px;
		margin: 0 auto;
		padding: 20px;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
		border-radius: 10px;
		background-color: white;
	}
	.invoice_top {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		.invoice_brand_logo {
			position: relative;
			width: 100%;
			height: 80px;
		}
		.invoice_company_info {
			text-align: flex-end;
			width: 100%;
			h1 {
				font-size: 20px;
				margin-bottom: 5px;
				color: $title-color;
			}
			p {
				margin: 0px;
			}
		}
	}
	.invoice_form {
		.select_wrapper_product {
			min-width: 280px;
		}
		.select_wrapper_user {
			min-width: 300px;
		}
		.ant-input {
			// border: none;
			padding: 2px 5px;
		}
		.ant-form-item-label {
			padding: 0;
			> label {
				padding: 0 5px;
				color: $label-text-color;
				margin-bottom: 2px;
				font-weight: 600 !important;
			}
		}
		.ant-form-item {
			margin: 0;
		}
	}
	.invoice_client_info {
		border-top: 1px solid $devider-color;
		margin: 10px 0 0 0;
		padding: 10px 0 0 0;
	}
	.invoice_order_info {
		border-bottom: 1px solid $devider-color;
		padding: 10px 0 10px 0;
	}
	.invoice_product_info {
		.custom_datatable_section {
			margin: 20px 0;
			background-color: rgb(253, 251, 251);
			border-radius: 5px;
			overflow: hidden;
		}
		.custom_datatable_section .custom_datatable_wrapper {
			padding: 30px;
			.table_field_addbutton {
				margin: 10px auto;
				width: 80%;
				background-color: rgb(245, 241, 241);
				text-align: center;
				padding: 8px 10px;
				border: 1px dashed $title-color;
				cursor: pointer;
				font-size: 15px;
				font-weight: 600;
				color: $title-color;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 5px;
			}
		}
		.custom_datatable_section .custom_datatable_wrapper .custom_datatable {
			width: 100%;
		}
		.custom_datatable_section .custom_datatable_wrapper .custom_datatable .custom_datatable_tablehead {
			background-color: white;
			tr {
				th {
					padding: 8px;
					font-size: 14px;
					font-weight: 500;
					min-width: 100px;
					text-align: center;

					&:first-child {
						text-align: flex-start;
						min-width: 50px;
					}
					&:nth-child(2) {
						text-align: flex-start;
						min-width: 320px !important;
					}
					&:nth-child(6) {
						text-align: flex-end !important;
					}
				}
			}
		}
		.custom_datatable_section .custom_datatable_wrapper .custom_datatable .custom_datatable_tablebody {
			padding: 5px;

			tr {
				border-bottom: 1px solid $devider-color;
				cursor: pointer;
				position: relative;

				&:hover {
					background-color: rgb(238, 237, 237);
				}
				&:last-child {
					border: none;
				}
				.delete_action_button {
					position: absolute;
					top: 50%;
					right: -18px;
					font-size: 18px;
					display: block;
					transform: translateY(-60%);
					color: rgba(235, 51, 51, 0.747);
				}

				td {
					padding: 5px;
					font-size: 13px;
					text-align: center;
					color: black;
					font-weight: 400;
					min-width: 100px;

					&:first-child {
						text-align: flex-start;
						min-width: 50px;
						color: $title-color;
					}
					&:nth-child(2) {
						text-align: flex-start;
						min-width: 320px !important;
					}

					&:nth-child(6) {
						text-transform: capitalize;
						text-align: flex-end !important;
						font-weight: 600;
						padding-right: 10px;
					}
					.invoice_qty_counter {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 10px;
						text-align: center;
						.qty_counter_button,
						.qty_counter_button {
							background-color: rgb(224, 221, 221);
							font-weight: bold;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 5px;
							width: 20px;
							height: 20px;
							padding: 5px;
						}
						.qty_count {
							font-weight: 600;
						}
					}
				}
			}
		}
	}
	.invoice_summary_section {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.invoice_summary {
			padding: 5px;
			width: 40%;
			text-align: flex-end;
			.invoice_summary_item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 5px 10px;
				p {
					margin: 0;
				}
				.ant-input {
					text-align: flex-end;
					padding: 5px;
					width: 100px;
					border: 1px solid $devider-color;
				}
			}
			.Total_due {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 5px 10px;
				font-size: 16px;
				font-weight: 600;
				border-top: 5px double $devider-color;
			}
		}
	}
	.invoice_additional_info {
		margin-top: 20px;
		.invoice_additional_items {
			margin-top: 10px;
			width: 50%;
			h1 {
				font-size: 16px;
				margin-bottom: 8px;
				font-weight: 600;
			}
		}
	}
	.button_wrapper {
		text-align: right;
		margin-top: 20px;
	}
}

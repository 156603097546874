.about_top_section {
	padding: 50px 0px;
	height: 65vh;
	position: relative;
	background-image: url('/images/page/about/about-bg.png');
	background-repeat: no-repeat;
	z-index: 1;
	background-position: center;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	&::after {
		position: absolute;
		content: '';
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: -1;
		background: #325aab94;
	}
	.section_title {
		max-width: 700px;
		text-align: center;
		margin: auto;
		font-size: 74px;
		color: white;
		font-weight: 600;
	}
}
.about_section_wrapper {
	background-color: white;
	padding: 50px 10px;

	.about_title {
		margin: 0;
		text-align: center;
		font-size: 48px;
		font-weight: 600;
	}

	.about_content_wrapper {
		display: flex;
		padding: 0 10px;
		gap: 20px;
		margin-top: 80px;
		.about_content_item_wrapper {
			display: flex;
			gap: 10px;
			.about_info_section {
				padding: 0 30px 0 0;

				.about_info_description {
					font-size: 16px;
					color: $home_paragraph_color;
					line-height: normal;
					text-align: justify;
				}
			}
			.about_info_image_section {
				position: relative;
				min-width: 480px;
				max-width: 480px;
				width: 100%;
				height: 420px;

				img {
					object-fit: contain;
					object-position: center;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.about_top_section {
		padding: 20px 0px;
		height: 40vh;

		.section_title {
			font-size: 36px;
		}
	}
	.about_section_wrapper {
		background-color: white;
		padding: 10px;

		.about_title {
			font-size: 30px;
			margin-top: 10px;
		}

		.about_content_wrapper {
			display: flex;
			padding: 0;
			margin-top: 50px;
			.about_content_item_wrapper {
				display: flex;
				flex-direction: column-reverse;
				gap: 20px;
				.about_info_section {
					padding: 0;

					.about_info_description {
						font-size: 15px;
						color: $home_paragraph_color;
						line-height: normal;
						text-align: justify;

					}
				}
				.about_info_image_section {
					position: relative;
					min-width: 100%;
					width: 100%;
					height: 300px;
					img {
						object-fit: contain;
						object-position: center;
					}
				}
			}
		}
	}
}

.dashboard_profile_section{
	margin-top: 20px;
	padding: 10px 20px;
	border-radius: 15px;

	.dashboard_profile_section_title{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 700;

		h5 {
			margin: 0;
		}

		.title {
			a {
				display: flex;
				align-items: center;
			}

			a:hover {
				color: blue;
			}
		}

		.dashboard_profile_section_title_checkbox_label {
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 7px 15px;
			border-radius: 10px;
			font-size: 14px;
			border: 1px solid rgb(238, 235, 235);

			h5 {
				margin: 0;
				font-size: 14px;
			}
		}

		.dashboard_profile_section_title_checkbox {
			display: none;
		}
	}
}

.dashboard_profile_setting {
	.user_profile_button {
		padding: 5px 10px;
		background-image: $gradient-primary;
		color: white;
		font-size: 14px;
		font-weight: 600;
		border-radius: 5px;

		&:hover {
			background-image: $gradient-primary-hover;
		}
	}
}

.profile_image_title {
	padding: 5px 10px;
	margin: 0;
	display: inline-block;
}

.profile_image_wrapper{
	display: flex;
	align-items: center;
	gap: 40px;

	.profile_image {
		margin-bottom: 30px;
	}
}

.select_source_form_step_wrapper {
	max-width: 580px;
	margin: auto;
	margin-top: 40px;
	.from_item_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 5px;
		.item_label {
			min-width: 120px;
			margin: 0;
			font-size: 14px !important;
			text-transform: capitalize;
			font-weight: 600;
		}
		.ant-form-item {
			margin: 0;
			width: 100%;
		}
		.ant-input {
			border: none;
			border-bottom: 1px solid rgba(221, 219, 219, 0.288);
			background-color: transparent;
			padding: 3px 5px;
			color: black;
			font-size: 14px;
			font-weight: 600;
			height: 100%;
			&:focus {
				&.ant-input {
					border: none;
					border-bottom: 1px solid white;
				}
			}
			&::placeholder {
				font-weight: 400 !important;
			}
		}
	}
}
.manage_permission_step_wrapper {
	max-width: 580px;
	margin: auto;

	.permission_table_wrapper {
		table {
			width: 100%;
		}
		.permission_title {
			display: flex;
			align-items: center;
			p {
				flex: 1;
				margin: 0;
				font-size: 15px;
				font-weight: 600;
			}
		}
		.permission_item_wrapper {
			padding: 5px 0;
			max-height: 400px;
			overflow-y: scroll;

			li {
				display: flex;
				align-items: center;
				padding: 5px 0;
				border-bottom: 1px solid $devider-color;
				&:last-child {
					border: none;
				}

				.permission_item_title_wrapper {
					flex: 1;
					display: flex;
					align-items: center;

					.permission_item_title {
						font-size: 14px;
						text-transform: capitalize;
						margin: 0;
						font-weight: 600;
					}
				}

				.permission_item_checkbox {
					flex: 1;
				}
			}
		}
	}
}

.page_breadcrumb_wrapper {
	margin-top: 80px;
	background: $secondary_bg;
	padding: 20px 0;
	min-height: 40vh;

	.breadcrumbs {
		background-color: rgb(236, 242, 247);
		padding: 3px 20px;
		border-radius: 5px;
		width: max-content;
		border-radius: 15px;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 15px;
			li {
				display: flex;
				align-items: center;
				gap: 5px;
				font-size: 14px;
				position: relative;
				color: $home_title_color;
				text-transform: capitalize;
				&::after {
					position: absolute;
					content: '/';
					right: -10px;
					top: 50%;
					transform: translateY(-50%);
				}
				&:last-child {
					font-weight: bold;
					color: #333;
					user-select: none;
					cursor: default;
					pointer-events: none;
				}
				&:last-child::after {
					display: none;
				}
			}
		}
	}

	.page_title_wrapper {
		text-align: center;
		
		margin: auto;
		background-color: red;

		h1 {
			margin-top: 20px;
			max-width: 600px;
			font-size: 32px;
			font-weight: 600;
			color: $home_title_color;
		}
	}
}
@media only screen and (min-width: 1450px) {
	.page_title_wrapper {
		h1 {
			font-size: 48px !important;
		}
	}
}
@media only screen and (max-width: 550px) {
	.breadcrumbs {
		ul {
			li {
				display: flex;
				align-items: center;
				gap: 5px;
				font-size: 12px !important;
				position: relative;
				color: $title-color;
				text-transform: capitalize;
			}
		}
	}
	.page_title_wrapper {
		h1 {
			font-size: 22px !important;
		}
	}
}

// pos header
.pos_header_wrapper {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	background-color: #ebf1ff;
	z-index: 1;

	.pos_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 60px;
		.header_item {
			flex: 1;
			text-align: center;
			&:first-child {
				text-align: start;
			}
			&:last-child {
				text-align: end;
			}

			.brand_title {
				font-size: 26px;
				color: #325aab;
				margin: 0;
			}

			.date_and_time {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 15px;

				p {
					font-size: 18px;
					text-transform: uppercase;
					margin: 0;
				}
			}

			.button_section_wrapper {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 20px;
				li {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 5px;
					padding: 5px 15px;
					background-color: #325aab;
					color: white;
					border-radius: 4px;
					cursor: pointer;
				}
			}
		}
	}
}
// pos content area
.dashboard-pos-section {
	margin-top: 60px;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.dashboard-pos-section-container {
		overflow: hidden !important;
		padding: 5px 0;
		.pos-list-wrapper {
			padding: 5px 0;
			.pos-product-list-wrapper {
				box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
				.pos-list-input {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 10px;
					padding: 5px;
				}
				.pos-table-wrapper {
					margin-top: 5px;
					max-height: 265px !important;
					overflow-y: auto;

					&::-webkit-scrollbar {
						width: 5px;
					}

					&::-webkit-scrollbar-track {
						background: #f1f1f1;
					}

					&::-webkit-scrollbar-thumb {
						background: #c7c5c5;
					}

					&::-webkit-scrollbar-thumb:hover {
						background: #555;
					}
					table {
						width: 100%;
						height: 100%;

						thead {
							tr {
								padding: 0 10px;
								border-bottom: 1px solid rgb(233, 230, 230);
								background-image: $gradient-primary;

								th {
									text-align: center;
									position: sticky;
									top: 0px;
									z-index: 1;
									padding: 8px 5px;

									color: white;
									text-transform: capitalize;
								}
							}
						}
						tbody {
							tr {
								position: relative;

								padding: 0 10px;
								border-bottom: 1px solid rgb(233, 230, 230);
								background-color: white;

								&:hover {
									box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
									background-color: rgb(252, 250, 250);
								}

								td {
									text-align: center;
									padding: 8px 5px;

									font-size: 12px;
									font-weight: 600;
									color: rgb(138, 135, 135);
									position: relative;
									line-height: 15px;

									.qty-counter {
										display: flex;
										align-items: center;
										justify-content: center;
										flex-wrap: wrap;
										gap: 10px;

										.qty-counter-button {
											padding: 0 5px;
											background: #e0dede;
											font-weight: 600;
											cursor: pointer;
											color: black;
											border-radius: 3px;
										}
									}
								}
							}
						}

						.pos-item-remove {
							padding: 5px;
							position: absolute;
							right: 10px;
							top: 8px;
							width: 16px;
							height: 16px;
							border-radius: 50%;
							background-color: rgb(247, 78, 78);
							display: flex;
							align-items: center;
							justify-content: center;
							color: white;
							font-size: 10px;
							font-weight: 700;
							cursor: pointer;
						}
					}
				}
			}
		}
		.pos-list-summary {
			margin: 5px;
			background-color: white;

			padding: 5px 10px;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
			.pos-list {
				display: flex;
				align-items: center;
				gap: 10px;
				justify-content: space-between;
				.pos-list-box {
					position: relative;
					width: 40%;
					&::after {
						position: absolute;
						content: '';
						width: 1px;
						height: 100%;
						top: 0;
						right: -25%;
						background-color: rgb(206, 204, 204);
					}
					&:last-child {
						&:after {
							display: none;
						}
					}
					.pos-list-item {
						display: flex;
						align-items: center;
						justify-content: space-between;
						span {
							font-size: 12px;
							font-weight: 600;
							color: rgb(95, 94, 94);
						}
						.pos-list-item-total-text {
							font-size: 12px;
							font-weight: 700;
						}
					}
				}
			}
		}
		.pos-payment-details {
			margin: 5px;
			background-color: white;
			padding: 5px 10px;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
			h6 {
				color: rgb(59, 59, 59);
				font-size: 17px;
				text-transform: capitalize;
			}
			.payment-list {
				display: flex;
				align-items: center;
				gap: 10px;
				justify-content: space-between;
				.payment-list-box {
					position: relative;
					width: 40%;
					&::after {
						position: absolute;
						content: '';
						width: 1px;
						height: 100%;
						top: 0;
						right: -25%;
						background-color: rgb(206, 204, 204);
					}
					&:last-child {
						&:after {
							display: none;
						}
					}
					.payment-list-item {
						display: flex;
						align-items: center;
						justify-content: space-between;
						position: relative;
						.payment-list-item-remove {
							padding: 5px;
							position: absolute;
							right: -5px;
							color: rgb(247, 78, 78);
							font-size: 12px;
							font-weight: 700;
							cursor: pointer;
						}
						span {
							font-size: 12px;
							font-weight: 600;
							color: rgb(95, 94, 94);
							padding-right: 30px;
						}
					}
				}
			}
		}
		.pos-details-print {
			margin: 10px 0;
			background-color: white;
			padding: 5px;
			.pos-button-wrapper {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-around;
				flex-wrap: wrap;
				gap: 20px;
				.pos-button {
					@mixin common-button-print {
						color: inherit;
						background: inherit;
						width: 110px;
						padding: 8px;
						text-transform: capitalize;
						border-radius: 5px;
						font-size: 14px;
						font-weight: 700;
						&:hover {
							background-color: white;
							filter: drop-shadow(1px #000);
							border: none;
							box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
							color: $primary-color;
						}
						&:focus {
							background-color: white;
							filter: drop-shadow(1px #000);
							border: none;
							box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
							color: $primary-color;
						}
					}
					.close-button {
						@include common-button-print;
						background-color: rgb(245, 225, 225);
						color: red;
						border: 1px solid red;
					}
					.hold-button {
						@include common-button-print;
						background-color: rgb(75, 177, 218);
						color: white;
						border: 1px solid white;
					}
					.unhold-button {
						@include common-button-print;
						background-color: rgb(75, 177, 218);
						color: white;
						border: 1px solid white;
					}
					.reset-button {
						@include common-button-print;
						background-color: red;
						color: white;
					}
					.print-button {
						@include common-button-print;
						background-color: $blue;
						color: white;
					}
				}
			}
		}
		.pos-calculator-section {
			display: flex;

			align-items: center;

			.calc-wrapper {
				max-width: 300px;
				padding: 15px 10px;
				box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
				background-color: rgb(243, 241, 241);
				input[type='text'] {
					height: 30px;
					width: 100%;
					background-color: rgb(199, 197, 197);
					color: white;
					border: none;
					padding: 5px 10px;
					text-align: right;
					font-size: 14px;
					letter-spacing: 1px;
					font-weight: 600;
					border-radius: 5px;
					&:focus {
						outline: none;
						border: 3px solid hsla(220, 89%, 73%, 0.8);
					}
					&:active {
						outline: none;
					}
				}
				#clear {
					grid-column: 1/3;
					grid-row: 1;
				}
				#result {
					grid-column: 3/5;
					grid-row: 5;
				}
				button {
					margin: 5px;
					background-color: white;
					text-transform: capitalize;
					cursor: pointer;
					border-radius: 15px;
					box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
					background-color: #e2dddd;
					font-size: 12px;
					font-weight: 600;
					&:focus {
						outline: none;
					}
					&:active {
						outline: none;
					}
					&:hover {
						background-color: black;
						color: white;
					}
				}
				.highlight {
					background-color: rgb(164, 211, 218);
				}
				.keypad {
					display: grid;
					grid-template-columns: repeat(4, auto);
					grid-auto-rows: minmax(20px, auto);
				}
			}
		}
		.pos-payment-section {
			padding: 5px;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
			.bloc-tabs {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 5px;
				background-color: white;
				padding: 5px;
			}
			.tabs {
				text-align: center;
				width: 100%;
				cursor: pointer;
				box-sizing: border-box;
				position: relative;
				outline: none;
			}

			.active-tabs {
				background: $gradient-primary;
				color: white;
			}

			button {
				font-size: 14px;
				border: 2px solid $blue;
				font-weight: 600;
				color: $blue;
				padding: 8px 5px;
				display: flex;
				align-items: center;
				gap: 5px;
				justify-content: center;
				border-radius: 5px;
			}
			.content-tabs {
				flex-grow: 1;
			}
			.content {
				background: white;
				padding: 5px;
				width: 100%;
				height: 100%;
				display: none;
			}
			.content h6 {
				color: black;
				margin-top: 10px;
			}

			.content p {
				width: 100%;
				height: 100%;
				color: black;
			}
			.active-content {
				display: block;
			}
		}
		.pos-payment-final-details {
			padding: 5px 0;
			margin: 5px 0;
			.payment-details-card-wrapper {
				display: grid;
				grid-template-columns: auto auto;
				grid-gap: 5px;
				.payment-details-card {
					background-color: white;
					color: black !important;
					padding: 5px 10px;
					box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
					span {
						font-size: 12px;
					}
					p {
						font-size: 14px;
						font-weight: 700;
						margin: 0;
						color: #706f6f;
					}
				}
			}
		}
	}
}
.posSummary {
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin-bottom: 10px;
	background-image: $gradient-primary;
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;

	.posSummaryCol {
		margin-right: 20px;

		h6 {
			margin: 0;
			color: white;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			gap: 5px;

			span {
				color: rgb(5, 230, 247);
			}
		}
	}
}


.pos-payment-section {
					padding: 5px;
					box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		
					.bloc-tabs {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 5px;
						background-color: white;
						padding: 5px;
					}
		
					.tabs {
						text-align: center;
						width: 100%;
						cursor: pointer;
						box-sizing: border-box;
						position: relative;
						outline: none;
					}
		
					.active-tabs {
						background: $gradient-primary;
						color: white;
					}
		
					button {
						font-size: 14px;
						border: 2px solid $blue;
						font-weight: 600;
						color: $blue;
						padding: 8px 5px;
						display: flex;
						align-items: center;
						gap: 5px;
						justify-content: center;
						border-radius: 5px;
					}
		
					.content-tabs {
						flex-grow: 1;
					}
		
					.content {
						background: white;
						padding: 5px;
						width: 100%;
						height: 100%;
						display: none;
					}
		
					.content h6 {
						color: black;
						margin-top: 10px;
					}
		
					.content p {
						width: 100%;
						height: 100%;
						color: black;
					}
		
					.active-content {
						display: block;
					}
				}
////dashboard service section
.dashboard-service-section {
  .dashboard-service-item {
    .dashboard-service-title {
      padding: 5px;
      .service-title {
        margin-bottom: 10px;
        text-align: center;
      }
      .service-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .service-add-button {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 3px 15px;
          background-image: $gradient-primary;
          color: white;
          border-radius: 5px;
          &:hover {
            background-image: $gradient-primary-hover;
          }
        }
      }
    }
    .dashboard-service-datatable {
      margin-top: 5px;
    }
  }
}
.sellservice-count-text {
  margin: 10px;
  padding: 2px;
  font-size: 16px;
  font-weight: 600;
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.due-amount-text {
  font-size: 15px;
  font-weight: 600;
}

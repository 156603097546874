// pos content area
.pos_sec_wrapper {
	margin-top: 60px;
	width: 100%;
	height: 100%;

	.pos_summary_content_wrapper {
		height: calc(100vh - 60px);
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		padding: 5px;

		.pos_cart_product_table_wrapper {
			height: 40%;
			overflow-y: scroll;

			.pos_cart_product_table {
				// padding-right: 20px;
				.table_head_wrapper {
					position: sticky;
					top: 0;
					display: flex;
					align-items: center;
					width: 100%;
					background-color: #325aab;
					color: white;
					.table_head_item {
						flex: 1;
						font-size: 13px;
						font-weight: 500;
						padding: 5px;
						text-align: center;
						&:first-child {
							flex: 4;
							text-align: left;
						}
						&:last-child {
							flex-shrink: 0;
							text-align: right;
						}
					}
					.action_head {
						width: 20px;
					}
				}
				.table_body_wrapper {
					.table_body_row {
						border: 1px solid rgb(221, 221, 221);
						width: 100%;
						display: flex;
						align-items: center;
						margin-top: 5px;
						border-radius: 3px;

						.action_button {
							width: 20px;
							cursor: pointer;
							padding: 0;
							border: none;
							.action_delete_button {
								font-size: 17px;
								color: rgb(243, 29, 29);
							}
						}
						.table_row_item {
							flex: 1;
							flex-shrink: 0;
							font-size: 12px;
							font-weight: 500;
							padding: 4px 5px;
							text-align: center;
							color: rgba(84, 84, 84, 1);
							&:first-child {
								flex: 4;
								flex-shrink: 0;
								text-align: left;
								line-height: normal;
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
								-webkit-transition: ease-in-out all 0.5s;
								transition: ease-in-out all 0.5s;
							}
							&:last-child {
								flex-shrink: 0;
								text-align: right;
							}

							.product_counter {
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: space-between;
								gap: 3px;
								.counter_button_inc,
								.counter_button_dec {
									width: 16px;
									height: 16px;
									line-height: normal;
									display: flex;
									align-items: center;
									justify-content: center;
									color: white;
									background-color: rgba(96, 75, 232, 1);
									font-size: 12px;
									font-weight: 600;
									border-radius: 3px;
								}
							}
						}
					}
				}
			}
		}

		.pos_summary_wrapper {
			// height: 40%;
			padding: 8px 0 5px 0;
			box-shadow: rgba(0, 0, 0, 0.171) 0px 2px 3px 0px inset;
			.pos_summary_item_wrapper {
				background-color: rgba(206, 225, 245, 0.46);
				padding: 0px 0px 15px 5px;
				margin-bottom: 10px;
				// overflow: hidden;
				.discountLabel {
					font-size: medium;
					color: red;
					:hover {
						color: rgb(209, 9, 9);
						cursor: pointer;
					}
				}
				.pos_summary_item {
					width: 100%;
					display: flex;
					align-items: center;
					padding: 0px 10px;
					font-size: 13px;
					font-weight: 500;
					.item_key {
						margin: 0;
						flex: 2;
						text-align: right;
						color: rgb(104, 103, 103);
					}
					.item_value {
						margin: 0;
						flex: 1;
						text-align: right;
						font-weight: 600;
					}
					&.with_bg {
						background-color: #604be8;
						padding: 3px 10px;
						.item_key,
						.item_value {
							color: white !important;
						}
					}
				}
			}
			.pos_button_wrapper {
				display: flex;
				align-items: center;
				gap: 20px;
				width: 100%;
				padding: 0 10px;
				.pos_button {
					flex: 1;
					text-align: center;
					padding: 6px;
					font-size: 14px;
					border-radius: 3px;
					font-weight: 600;
					&.hold {
						color: rgba(50, 90, 171, 1);
						border: 1px solid rgba(84, 110, 179, 1);
						background-color: rgba(207, 223, 255, 1);
					}
					&.pay {
						border: 1px solid rgba(238, 50, 50, 1);
						background-color: rgba(238, 50, 50, 1);
						color: white;
					}
					&.print {
						border: 1px solid rgba(50, 90, 171, 1);
						color: white;
						background-color: rgba(50, 90, 171, 1);
					}
				}
			}
		}
	}

	.pos_product_content_wrapper {
		height: calc(100vh - 60px);
		overflow: scroll;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

		.product_search_section {
			position: sticky;
			top: 0;
			z-index: 1;
			width: 100%;
			background-color: white;
			padding: 10px;

			.menu_slider_wrapper {
				position: relative;
				margin: 15px 0 10px 0;
				user-select: none;
				.swiper {
					margin: 0 30px;

					.swiper-wrapper {
						.swiper-slide {
							width: auto !important;
						}
					}

					.swiper-pagination {
						bottom: -5px;

						.swiper-pagination-bullet {
							width: 8px;
							height: 8px;
							transition: width 0.4s linear;
							border-radius: 0.3125rem;
							background-color: black;
						}
						.swiper-pagination-bullet-active {
							width: 20px;
							height: 8px;
							border-radius: 15px;
							// background-color: $primary-color;
						}
					}
				}

				.menu_slider_item_wrapper {
					padding: 0.3rem 1.2rem;
					border-radius: 1rem;
					cursor: pointer;

					background-color: rgba(235, 241, 255, 1);
					display: flex;
					align-items: center;
					gap: 5px;
					.menu_icon_wrapper {
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						width: 20px;
						aspect-ratio: 1/1;
					}
					.menu_title {
						margin: 0;
						font-size: 13px;
					}

					&:hover {
						background-color: rgb(166, 190, 238);
					}
					&.active_cat {
						background-color: rgba(50, 90, 171, 1);
						color: white;
					}
				}

				.button_next_slide,
				.button_prev_slide {
					// background-color: $bet-item-bg;
					width: 22px;
					aspect-ratio: 1/1;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					z-index: 1;
					top: 45%;
					transform: translateY(-45%);
					// color: $color-hint;
					cursor: pointer;
					font-weight: 300 !important;
					&:hover {
						// background: $background-hover;
					}
				}
				.button_next_slide {
					right: 0px;
				}
				.button_prev_slide {
					left: 0px;
				}
			}
		}

		.product_list_section {
			padding: 15px;
			.product_card_wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				border-radius: 3px;
				overflow: hidden;
				cursor: pointer;
				padding: 5px;
				position: relative;
				&:hover {
					box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
					.cart_button {
						left: 0;
					}
				}
				.product_image_wrapper {
					position: relative;
					width: 100%;
					height: 110px;
					padding: 5px;
					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
						object-position: center;
					}
				}
				.product_info_wrapper {
					text-align: center;
					.product_title {
						margin: 0;
						font-size: 12px;
						padding: 5px 0;
						color: rgb(122, 122, 122);
						font-weight: 500;
					}
					.product_amount {
						margin: 0;
						font-size: 14px;
						font-weight: 600;
						background-color: rgba(234, 239, 250, 1);
						color: rgb(110, 109, 109);
						padding: 3px;
					}
				}

				.cart_button {
					position: absolute;
					content: '';
					background-color: rgba(50, 90, 171, 0.89);
					top: 0;
					left: -100%;
					width: 100%;
					height: 30%;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: left 0.3s ease;
					.cart_button_icon {
						font-size: 35px;
						color: rgb(226, 221, 221);
					}
				}
			}
		}
	}
}

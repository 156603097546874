.features_section_wrapper {
	padding: 50px 0;
	background-color: rgb(230, 236, 250);
	.top_arrow_slider {
		.top_title {
			font-size: 14px;
		}
		.slider_top_title_wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 10px 0;
			.slider_top_title {
				margin: 0;
				font-size: 46px;
				font-weight: 600;
			}
			// .slider_button_wrapper {
			// 	display: flex;
			// 	align-items: center;

			// 	gap: 15px;

			// 	.slider_button {
			// 		display: flex;
			// 		align-items: center;
			// 		gap: 10px;
			// 		.button_next_slide,
			// 		.button_prev_slide {
			// 			color: white;
			// 			cursor: pointer;
			// 			background-color: #1398f1;
			// 			width: 35px;
			// 			height: 35px;
			// 			display: flex;
			// 			align-items: center;
			// 			justify-content: center;
			// 			border-radius: 50%;
			// 			&:hover {
			// 				background: rgb(224, 223, 223);
			// 				color: black;
			// 			}
			// 		}
			// 	}
			// }
		}

		.swiper .swiper-pagination-bullet {
			width: 10px !important;
			height: 10px !important;
		}
		.swiper .swiper-pagination-bullet-active {
			background: $home_secondary_color;
		}
		.swiper .swiper-wrapper {
			display: flex;
			margin: 100px 0 50px 0;
		}
		.swiper .swiper-wrapper .swiper-slide {
			padding: 30px;
			cursor: pointer;
			max-width: 370px;
			min-height: 370px;
			border-radius: 15px;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			background-color: #fff;
			&:nth-child(even) {
				margin-top: -40px !important;
			}
			.slider_item_card_wrapper {
				.slide_image_wrapper {
					position: relative;
					display: block;
					width: 100%;
					height: 100px;
					cursor: pointer;
					margin-bottom: 30px;
				}
				.slide_card_body {
					.slide_card_title {
						font-size: 18px;
					}
					.slide_card_dec {
						font-size: 12px;
						color: gray;
						line-height: 26px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.features_section_wrapper {
		padding: 30px 0 20px 0;
		.top_arrow_slider {
			.slider_top_title_wrapper {
				.slider_top_title {
					font-size: 26px;
				}
			}
			.swiper {
				margin: 10px 0;
				.swiper-wrapper {
					margin: 20px 0 50px 0;
					.swiper-slide {
						min-height: max-content;
						max-width: 100%;
						&:nth-child(even) {
							margin-top: 0px !important;
						}
						.slider_item_card_wrapper {
							.slide_image_wrapper {
								height: 60px;
								margin-bottom: 20px;
							}
							.slide_card_body {
								.slide_card_title {
									font-size: 16px;
								}
								.slide_card_dec {
									font-size: 12px;

									line-height: 24px;
								}
							}
						}
					}
				}
			}
		}
	}
}

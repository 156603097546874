.prodcut_profile_wrapper {
	max-width: 1400px;
	margin: auto;
	.from_item_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 5px;
		.item_label {
			min-width: 100px;
			margin: 0;
			font-size: 14px !important;
			text-transform: capitalize;
			font-weight: 600;
		}
		.ant-form-item {
			margin: 0;
			width: 100%;
		}
		.ant-input {
			border: none;
			border-bottom: 1px solid rgba(221, 219, 219, 0.288);
			background-color: transparent;
			padding: 3px 5px;
			color: black;
			font-size: 14px;
			font-weight: 600;
			height: 100%;
			&:focus {
				&.ant-input {
					border: none;
					border-bottom: 1px solid white;
				}
			}
			&::placeholder {
				font-weight: 400 !important;
			}
		}
	}

	.from_item_wrapper_col {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		margin-bottom: 5px;
		.item_label {
			min-width: 100px;
			margin: 0;
			font-size: 14px !important;
			text-transform: capitalize;
			font-weight: 500;
		}
		.ant-form-item {
			margin: 0;
			width: 100%;
		}
		.ant-input {
			border: none;
			border-bottom: 1px solid rgba(221, 219, 219, 0.288);
			background-color: transparent;
			padding: 3px 5px;
			color: black;
			font-size: 14px;
			font-weight: 600;
			height: 100%;
			&:focus {
				&.ant-input {
					border: none;
					border-bottom: 1px solid white;
				}
			}
			&::placeholder {
				font-weight: 400 !important;
			}
		}
	}

	.from_item_wrapper_multi_group {
		.item_title {
			margin: 0;
			font-size: 14px !important;
			text-transform: capitalize;
			font-weight: 500;
		}
		.from_item_wrapper_multi_wrapper {
			display: flex;
			align-items: center;
			.from_item_wrapper {
				display: flex;
				align-items: center;
				gap: 5px;

				.item_label {
					min-width: 5px;
					margin: 0;
					font-size: 14px !important;
					text-transform: capitalize;
					font-weight: 500;
				}
				.ant-form-item {
					margin: 0;
					width: 100%;
				}
				.ant-input {
					border: none;
					border-bottom: 1px solid rgba(221, 219, 219, 0.288);
					background-color: transparent;
					padding: 3px 5px;
					color: black;
					font-size: 14px;
					font-weight: 600;
					height: 100%;
					&:focus {
						&.ant-input {
							border: none;
							border-bottom: 1px solid white;
						}
					}
					&::placeholder {
						font-weight: 500 !important;
						font-size: 11px;
					}
				}
			}
		}
	}

	.ant-input-affix-wrapper {
		border: none;
	}
}
.prodcut_profile_wrapper .product_profile {
	padding: 10px 30px;
	.product_profile_section_title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 700;

		h5 {
			margin: 0;
		}

		.title {
			a {
				display: flex;
				align-items: center;
			}

			a:hover {
				color: blue;
			}
		}

		.product_profile_section_title_checkbox_label {
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 7px 15px;
			border-radius: 10px;
			font-size: 14px;
			border: 1px solid rgb(238, 235, 235);

			h5 {
				margin: 0;
				font-size: 14px;
			}
		}

		.product_profile_section_title_checkbox {
			display: none;
		}
	}
}
.prodcut_profile_wrapper .product_profile .product_profile_info_section {
	display: flex;
	align-items: flex-start;
	gap: 20px;
	.product_barcode_image_wrapper {
		.product_profile_image_wrapper {
			.ant-upload-wrapper {
				.ant-upload {
					width: 250px;
					height: 250px;
					margin: 0;
					padding: 5px;
					border-radius: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.ant-upload-drag {
					background-color: transparent;
					width: 250px;
					height: 250px;
					.ant-upload-btn {
						margin: 0;
						padding: 5px;
						border-radius: 0;
						display: table;
					}
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: center;
				}
			}
			.product_profile_image {
				position: relative;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.product_profile_image_view_wrapper {
			border: 1px solid $devider-color;
			border-radius: 10px;
			overflow: hidden;
		}
	}

	.product_profile_info_wrapper {
		width: 100%;
		.product_title {
			font-size: 32px;
			color: $title-color;
		}
		.product_info_measer {
			display: flex;
			gap: 20px;
			justify-content: space-between;
			.product_measer_info {
				flex: 2;
			}
			.product_brand_info {
				flex: 5;
			}
		}
	}
}
.prodcut_profile_wrapper .product_profile .product_profile_summary {
	margin-top: 10px;
	padding: 20px 0;

	.product_profile_tab_wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		&.fixed {
			z-index: 100;
			background-color: $bg-color;
			position: fixed;
			top: 50px;
			right: 0;
			margin: auto;
			width: calc(100% - 200px);
			// margin-left: 200px;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			scroll-behavior: smooth;
		}
		ul {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 5px;
			background-color: rgb(240, 237, 237);
			border-radius: 25px;
			li {
				padding: 2px 10px;
				cursor: pointer;
				text-transform: capitalize;
				border-radius: 25px;
				&.active {
					background-color: $title-color;
					color: $white-text;
					transition: all ease 0.3s;
					&:hover {
						background-color: $title-color;
						color: $white-text;
						transition: all ease 0.3s;
					}
				}
				&:hover {
					background-color: #92c3e971;
					color: black;
					transition: all ease 0.3s;
				}
			}
		}
	}
	.product_profile_content_wrapper {
		margin-top: 50px;

		// content item 1 start
		.product_profile_overview_content_wrapper {
			.product_profile_overview_content {
				display: flex;
				align-items: flex-start;
				gap: 20px;

				.overview_quantity_box_wrapper {
					flex: 1;
					min-height: 350px;
					padding: 20px;
					background-image: $gradient-secondary;
					color: $white-text;
					.box_top {
						.top_title {
							font-size: 22px;
							font-weight: 600;
						}
						.top_count {
							font-size: 34px;
							margin: 0;
						}
					}
				}
				.overview_pricing_box_wrapper {
					flex: 1;
					min-height: 350px;
					padding: 20px;
					background-color: rgb(240, 240, 240);
					.box_top {
						.top_title {
							font-size: 22px;
							font-weight: 600;
						}
					}
				}
				.item_input_width {
					max-width: 150px;
				}
				.ant-input {
					border: none;
					border-bottom: 1px solid rgba(221, 219, 219, 0.288);
					background-color: white;
					padding: 3px 5px;
					color: black;
					font-size: 14px;
					font-weight: 600;
					height: 100%;
					text-align: flex-end;
					&:focus {
						&.ant-input {
							border: none;
							border-bottom: 1px solid white;
						}
					}
				}
			}
			.product_profile_overview_content_summary {
				margin: 10px 0;
				display: flex;
				.summary_part1 {
					flex: 1;
					padding: 20px;
					.summary_title {
						font-size: 18px;
						font-weight: 600;
					}
					.summary_wrapper {
						display: grid;
						grid-template-columns: auto auto;
						column-gap: 20px;
						row-gap: 5px;
						.summary_item_wrapper {
							display: flex;
							align-items: center;
							justify-content: space-between;
							gap: 10px;
							.item_title_wrapper {
								display: flex;
								align-items: center;
								gap: 5px;
								.item_title {
									margin: 0;
								}
							}

							span {
								font-size: 16px;
								font-weight: 600;
							}
						}
					}
				}
				.summary_part2 {
					flex: 1;
					padding: 20px;
					.summary_title {
						font-size: 18px;
						font-weight: 600;
					}
				}
			}

			.button_wrapper {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				margin-top: 20px;
				.global_button {
					background-image: $gradient-secondary;
					color: white;
					padding: 8px 10px;
					border-radius: 5px;
					font-size: 14px;
					font-weight: 600;
					width: 100%;
					max-width: 250px;
				}
			}
		}
		// content item 1 end

		// content item 2 start
		// content item 2 end

		// content item 3 start
		// content item 3 end

		// content item 4 start
		// content item 4 end
	}
}

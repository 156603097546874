.footer_section_wrapper {
	padding: 50px 0 0 0;
	background: #f5f8ff;
}
.footer_section_wrapper .footer_section .footer_item_wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
}
.footer_section_wrapper .footer_section .footer_copyright_title {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	margin-top: 50px;
}
.footer_section_wrapper .footer_section .footer_item_wrapper .footer_item {
	.footer_about_section {
		.footer_about_image_wrapper {
			position: relative;
			width: 250px;
			height: 60px;
			margin-top: -30px;
		}
		.footer_about_info {
			margin-top: 15px;
			font-size: 14px;
			.footer_about {
				max-width: 300px;
			}
			.footer_about_contact {
				display: flex;
				align-items: center;
				gap: 10px;
				margin-top: 5px;

				span {
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
	}
}
.footer_section_wrapper .footer_section .footer_item_wrapper .footer_item {
	.footer_link_section {
		.footer_link_item_wrapper {
			padding: 5px;
			.footer_link_item_title {
				font-size: 16px;
				text-transform: capitalize;
				font-weight: 600;
			}
			.footer_link_item_list {
				font-size: 14px;
				text-transform: capitalize;
				cursor: pointer;
				font-weight: 500;
				margin-top: 5px;
				transition: all 1s;
				&:hover {
					transition: all 0.5s;
					color: rgb(72, 190, 206);
					transform: translateX(5px);
				}
				&:first-child {
					margin: 0px;
				}
			}
		}
	}
}
.footer_section_wrapper .footer_section .footer_item_wrapper .footer_item {
	.footer_address_section {
		padding: 5px;
		max-width: 250px;
		.footer_address_title {
			font-size: 16px;
			text-transform: capitalize;
			font-weight: 600;
		}
		.footer_address_subtitle {
			font-size: 14px;
			font-weight: 500;
			margin: 25px 0;
		}
		.footer_social_address_wrapper {
			display: flex;
			align-items: center;
			gap: 10px;
			.footer_social_icon_wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
				border-radius: 50%;
				padding: 8px;
				transition: all 1s;

				&:hover {
					transition: all 0.5s;
					color: white;
					background-color: $home_secondary_color;
				}
			}
		}
	}
}

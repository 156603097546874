.choose_us_section_wrapper {
	padding: 50px 0 100px 0;
}
.choose_us_section_wrapper .choose_us_title_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0;

	.choose_us_title {
		margin: 0;
		font-size: 46px;
		font-weight: 600;
	}
}
.choose_us_section_wrapper .choose_us_card_item_wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 40px;
	margin-top: 80px;
}

.choose_us_section_wrapper .choose_us_card_item_wrapper .choose_us_card {
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	border-radius: 10px;
	max-width: 360px;
	min-height: 300px;
	min-width: 360px;
	position: relative;

	.choose_us_card_icon_wrapper {
		position: absolute;
		left: -15px;
		top: -10px;
		width: 75px;
		height: 75px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		border: 5px solid $home_secondary_color;
		border-block-start-style: dotted;
		border-block-start-color: #41acaa;
		border-inline-end-color: white;
		border-inline-end-width: 5px;
		transform: rotate(42deg);

		.choose_us_card_title {
			transform: rotate(-42deg);
			margin: 0;
			color: #41acaa;
			font-weight: 600;
		}
	}

	.choose_us_info {
		margin-top: 70px;
		.choose_us_info_title {
			font-size: 18px;
			text-transform: capitalize;
		}
		.choose_us_info_des {
			margin: 0;
			font-size: 14px;
			font-weight: 400;
			color: $home_paragraph_color;
		}
	}
}

@media only screen and (max-width: 768px) {
	.choose_us_section_wrapper {
		padding: 50px 0;
	}
	.choose_us_section_wrapper .choose_us_title_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.choose_us_title {
			font-size: 26px;
		}
	}
	.choose_us_section_wrapper .choose_us_card_item_wrapper {
		display: flex;
		// flex-direction: column;
		justify-content: center;

		gap: 30px;
		margin-top: 50px;
		padding: 0 10px 0 15px;
	}

	.choose_us_section_wrapper .choose_us_card_item_wrapper .choose_us_card {
		padding: 20px 15px;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		border-radius: 10px;
		max-height: max-content !important;
		min-width: 300px;
		position: relative;
		.choose_us_card_icon_wrapper {
			width: 65px;
			height: 65px;

			.choose_us_card_title {
				transform: rotate(-42deg);
				margin: 0;
				color: #41acaa;
				font-weight: 600;
			}
		}

		.choose_us_info {
			margin-top: 70px;
			.choose_us_info_title {
				font-size: 16px;
				text-transform: capitalize;
			}
			.choose_us_info_des {
				margin: 0;
				font-size: 12px;
				font-weight: 400;
				color: $home_paragraph_color;
				display: block;
				max-height: max-content;
			}
		}
	}
}

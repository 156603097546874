.onboard_section_wrapper {
	background: url('/background/onboard-bg.jpg');
	width: 100%;
	min-height: 100vh;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;

	.onboard_section {
		border-radius: 10px;
		background-color: white;
		min-height: 85vh;
		width: 100%;
		max-width: 620px;
		margin: auto;
		padding: 20px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

		.ant-steps-item-finish {
			.ant-steps-item-icon {
				background-image: $gradient-secondary;
				.ant-steps-icon {
					color: white;
				}
			}
		}
		.ant-steps-item-process {
			.ant-steps-item-icon {
				background-image: $gradient-secondary;
				border: transparent;
			}
		}

		.step_content_wrapper {
			margin: 20px 0;
			padding: 10px;
		}
		.ant-form-item-label > label {
			font-size: 15px;
			font-weight: 600;
		}
		.step_button_wrapper {
			display: flex;
			align-items: center;
			gap: 20px;
			justify-content: space-around;

			.button_gradiend_primary {
				background: $gradient-secondary;
				padding: 5px 30px;
				color: white !important;
				text-transform: capitalize;
				font-weight: 600;
				border-radius: 3px;
				border: none;
				font-size: 14px;
				transition: all 0.5s ease-out 0s;
				background-size: 200%;
				cursor: pointer;
				width: 100%;
				max-width: 220px;
				height: 40px;
				&:hover {
					background-position: right center;
				}
			}
		}

		.shoptype_radio_custom {
			.ant-radio-group {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 5px;
				max-height: 45vh;
				overflow-y: scroll;

				&::-webkit-scrollbar {
					width: 4px;
				}
				&::-webkit-scrollbar-track {
					background: transparent;
				}

				&::-webkit-scrollbar-thumb {
					background-image: $gradient-secondary;
				}
			}
			.ant-radio-button-wrapper {
				width: 100%;
				overflow: hidden;
				display: flex;
				align-items: center;
				min-height: 50px;
				padding: 10px;
				border: 1px solid $devider-color;

				&:first-child {
					border-start-start-radius: 0px;
					border-end-start-radius: 0px;
				}
				&:last-child {
					border-start-end-radius: 0px;
					border-end-end-radius: 0px;
				}

				&::before {
					width: 0 !important;
				}

				.select_shop_card_wrapper {
					display: flex;
					align-items: center;
					gap: 10px;

					span {
						font-size: 14px;
						font-weight: 600;
						text-transform: capitalize;
					}
					.image_active_color {
						filter: grayscale(100%);
					}
				}
			}
			.ant-radio-button-wrapper-checked {
				.image_active_color {
					filter: none !important;
				}
				&:not(.ant-radio-button-wrapper-disabled) {
					color: white;

					&:hover {
						color: white;
					}
				}
			}
			.ant-radio-button-checked {
				background-image: $gradient-secondary;
				border: 1px solid $sub-text-color;
			}
			.ant-radio-button {
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			}
		}
	}
}

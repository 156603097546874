.invoice2_section_wrapper {
	padding: 40px 0;

	.invoice2_section_container {
		max-width: 920px;
		margin: 0 auto;
		padding: 20px;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
		border-radius: 10px;
		background-color: white;
	}
	.invoice2_top {
		display: flex;
		justify-content: space-between;

		.invoice2_company_address_wrapper {
			display: flex;
			gap: 10px;
			align-items: flex-start;

			.invoice2_brand_text {
				position: relative;
				width: 220px;
				// height: 80px;
				color: #4088c0;
				text-transform: uppercase;
				text-align: justify;
				border-right: 0.2em solid;
			}
			.invoice2_brand_logo {
				position: relative;
				width: 220px;
				height: 80px;
			}
			.invoice2_company_address {
				display: flex;
				flex-direction: column;
				gap: 10px;
				.invoice2_company_address_item {
					display: flex;
					align-items: center;
					gap: 8px;
					span {
						display: flex;
						align-items: center;
						justify-content: center;
						background-image: $gradient-secondary;
						padding: 5px;
						color: white;
					}
					.invoice2_company_address_text {
						font-size: 13px;

						p {
							margin: 0 !important;
							line-height: 16px;
						}
					}
				}
			}
		}

		.invoice2_company_info {
			text-align: flex-end;

			h1 {
				font-size: 26px;
				margin-bottom: 5px;
				color: $title-color;
			}
			.text_bg {
				margin: 0px;
				background-image: $gradient-secondary;
				padding: 2px 10px;
				color: $white-text;
			}
			p {
				margin: 0px;
			}
			.invoice_date {
				font-size: 16px;
				font-weight: 600;
			}
			.blurry {
				filter: blur(2px);
			}
		}
	}
	.invoice2_form {
		.ant-input {
			border: none;
			border-bottom: 1px solid rgba(221, 219, 219, 0.288);
			background-color: transparent !important;
			padding: 3px 5px;
			color: $white-text;
			font-size: 14px;
			font-weight: 600;
			height: 100%;
		}
		.ant-form-item-label {
			padding: 0;
			> label {
				padding: 0;
				color: white;
				margin: 0;
				font-weight: 600 !important;
			}
		}
		.ant-form-item {
			margin: 0;
		}
	}

	.invocie2_client_info_wrapper {
		margin-top: 20px;
		// background-image: $gradient-secondary;
		color: white;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		gap: 20px;
		background-image: url('../../public/images/bg-invoice.jpg');
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, rgba($title-color, 1), rgba($sub-text-color, 0.8));
		}

		.invoice2_client_info {
			min-width: 300px;
			z-index: 1;
			.ant-picker {
				background-color: transparent;
				padding: 0;
				border: none;
				.ant-picker-suffix {
					color: white;
				}
				.ant-picker-input > input {
					color: white;
					&::placeholder {
						color: white;
					}
				}
			}
			.item_wrapper {
				display: flex;
				align-items: center;
				gap: 10px;
				margin-bottom: 10px;
				.item_label {
					min-width: 60px;
					margin: 0;
					font-size: 14px !important;
					text-transform: capitalize;
					font-weight: 600;
				}
				.item_value {
					margin: 0;

					max-width: 100px;
					font-size: 13px;
					font-weight: 500;
					color:rgba(255, 255, 255, 0.76);
				}
			}
		}
		.invocie2_order_info_wrapper {
			z-index: 1;
			min-width: 400px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			border-top: 5px double white;
			border-bottom: 5px double white;
			.invocie2_order_info {
				display: flex;
				flex-direction: column;
				.ant-picker {
					background-color: transparent;
					padding: 0;
					border: none;
					.ant-picker-suffix {
						color: white;
					}
					.ant-picker-input > input {
						color: white;
						&::placeholder {
							color: white;
						}
					}
				}
				p {
					margin: 0;
					font-size: 18px;
					font-weight: 600;
				}
			}
		}
	}

	.invoice2_product_info {
		.custom_datatable_section {
			margin: 20px 0;
			padding: 12px;
		}
		.custom_datatable_section .custom_datatable_wrapper {
			.table_field_addbutton {
				margin: 10px auto;
				width: 80%;
				background-color: rgb(245, 241, 241);
				text-align: center;
				padding: 8px 10px;
				border: 1px dashed $title-color;
				cursor: pointer;
				font-size: 15px;
				font-weight: 600;
				color: $title-color;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 5px;
			}
		}
		.custom_datatable_section .custom_datatable_wrapper .custom_datatable {
			width: 100%;
		}
		.custom_datatable_section .custom_datatable_wrapper .custom_datatable .custom_datatable_tablehead {
			background-image: $gradient-secondary;
			color: $white-text;
			tr {
				th {
					padding: 8px;
					font-size: 14px;
					font-weight: 500;
					min-width: 100px;
					text-align: center;

					&:first-child {
						text-align: flex-start;
						min-width: 50px;
					}
					&:nth-child(2) {
						text-align: flex-start;
						min-width: 300px !important;
					}
					&:nth-child(6) {
						text-align: flex-end !important;
					}
				}
			}
		}
		.custom_datatable_section .custom_datatable_wrapper .custom_datatable .custom_datatable_tablebody {
			padding: 5px;

			tr {
				border-bottom: 1px solid $devider-color;
				cursor: pointer;
				position: relative;

				&:hover {
					background-color: rgba(245, 244, 244, 0.671);
				}
				&:last-child {
					border: none;
				}
				.delete_action_button {
					position: absolute;
					top: 50%;
					right: -16px;
					font-size: 18px;
					display: block;
					transform: translateY(-60%);
					color: rgba(235, 51, 51, 0.747);
				}

				td {
					padding: 5px;
					font-size: 13px;
					text-align: center;
					color: black;
					font-weight: 400;
					min-width: 100px;

					&:first-child {
						text-align: flex-start;
						min-width: 50px;
						color: $title-color;
					}
					&:nth-child(2) {
						text-align: flex-start;
						min-width: 300px !important;
					}

					&:nth-child(6) {
						text-transform: capitalize;
						text-align: flex-end !important;
						font-weight: 600;
						padding-right: 10px;
					}

					.ant-input {
						color: black;
					}
					.invoice_qty_counter {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 10px;
						text-align: center;
						.qty_counter_button,
						.qty_counter_button {
							background-color: rgb(224, 221, 221);
							font-weight: bold;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 5px;
							width: 20px;
							height: 20px;
							padding: 5px;
						}
						.qty_count {
							font-weight: 600;
						}
					}
				}
			}
		}
	}
	.invoice2_summary_section {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.payment_dropdown_wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px 10px;
			background-color: rgba(64, 137, 192, 0.863);
			color: white;
			cursor: pointer;
			border-radius: 3px;
			user-select: none;

			h6 {
				margin: 0;
				font-size: 14px;
				font-weight: 600;
			}
		}

		.item_wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			margin-bottom: 10px;
			padding: 5px 10px;
			.item_value {
				margin: 0;

				max-width: 100px;
				font-size: 14px;
				font-weight: 500;
				color: $home_paragraph_color;
				.ant-form-item {
					margin: 0;
					width: 100%;
				}

				.ant-input-number {
					border: transparent;
					border-bottom: 1px solid rgba(168, 165, 165, 0.664);
					background-color: transparent;
					padding: 0;
					color: black;
					font-size: 14px;
					font-weight: 600;
					height: 100%;
					outline: none;
					&:focus {
						border: transparent;
						&.ant-input {
							border-bottom: 1px solid rgba(221, 219, 219, 0.664);
						}
					}
					&::placeholder {
						font-weight: 400 !important;
					}
				}
			}
			.item_label {
				min-width: 100px;
				margin: 0;
				font-size: 14px !important;
				text-transform: capitalize;
				font-weight: 600;
			}
		}
		.invoice2_summary {
			padding: 10px;
			width: 40%;
			text-align: flex-end;
			background-color: $header_bg;
			.invoice2_summary_item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 5px 10px;
				p {
					margin: 0;
				}

				.ant-input {
					text-align: flex-end;
					padding: 5px;
					width: 100px;
					border: 1px solid $devider-color;
					color: black !important;
				}
			}
			.total_amount {
				display: flex;
				align-items: center;
				justify-content: space-between;

				padding: 5px 10px;
				font-size: 16px;
				font-weight: 600;
			}
			.total_due {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 10px;
				padding: 5px 10px;
				font-size: 16px;
				font-weight: 600;
				border-top: 5px double $devider-color;
			}
		}
	}
	.invoice2_additional_info {
		margin-top: 20px;
		.invoice2_additional_items {
			margin-top: 10px;
			h1 {
				font-size: 16px;
				margin-bottom: 8px;
				font-weight: 600;
			}
			.ant-input {
				color: black;
			}
		}
	}
	.button_wrapper {
		text-align: right;
		margin-top: 20px;
	}
}

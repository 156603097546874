.product_barcode_section_wrapper {
	padding: 5px;
}
.product_barcode_section_wrapper .product_add_section_wrapper {
	.item_wrapper {
		display: flex;

		flex-direction: column;
		gap: 10px;
		margin-bottom: 10px;
		.item_label {
			min-width: 100px;
			margin: 0;
			font-size: 16px;
			text-transform: capitalize;
			font-weight: 600;
		}

		.item_value {
			margin: 0;

			max-width: 400px;
			font-size: 16px;
			font-weight: 600;
			color: $home_paragraph_color;
			.ant-form-item {
				margin: 0;
				width: 100%;
			}

			.ant-input-number {
				border: transparent;
				border-bottom: 1px solid rgba(168, 165, 165, 0.664);
				background-color: transparent;
				padding: 0;
				color: black;
				font-size: 14px;
				font-weight: 600;
				height: 100%;
				outline: none;
				&:focus {
					border: transparent;
					&.ant-input {
						border-bottom: 1px solid rgba(221, 219, 219, 0.664);
					}
				}
				&::placeholder {
					font-weight: 400 !important;
				}
			}
		}
	}
}
.product_barcode_section_wrapper .product_list_section_wrapper {
	.product_list_table_wrapper {
		margin-top: 20px;
		table {
			width: 100%;
			color: $price-title-color;

			thead {
				tr {
					background-color: rgb(237, 244, 247);
					border-bottom: 1px solid $devider-color;
					th {
						padding: 8px 10px;
						font-size: 12px;
						font-weight: 700;
						text-align: center;

						&:first-child {
							min-width: 250px;
							max-width: 300px;
							text-align: left;
						}
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid $devider-color;
					cursor: pointer;
					&:hover {
						background-color: rgb(243, 248, 248);
					}
					&:last-child {
						border: none;
					}
					td {
						padding: 8px 10px;
						color: rgba(54, 53, 53, 0.877);
						font-weight: 500;
						font-size: 12px;
						text-align: center;
						&:first-child {
							min-width: 250px;
							max-width: 300px;
							text-align: left;
						}
					}
				}
			}
		}

		.item_wrapper {
			.item_value {
				margin: 0;
				font-size: 14px;
				font-weight: 600;
				color: $home_paragraph_color;
				.ant-form-item {
					margin: 0;
				}

				.ant-input-number {
					border: transparent;
					border-bottom: 1px solid rgba(168, 165, 165, 0.664);
					background-color: transparent;
					padding: 0;

					height: 100%;
					outline: none;
					&:focus {
						border: transparent;
						&.ant-input {
							border-bottom: 1px solid rgba(221, 219, 219, 0.664);
						}
					}
					&::placeholder {
						font-weight: 400 !important;
					}

					input {
						text-align: center;
						font-size: 14px;
						font-weight: 500;
					}
				}
			}
			.item_label {
				min-width: 100px;
				margin: 0;
				font-size: 14px !important;
				text-transform: capitalize;
				font-weight: 600;
			}
		}
	}
}

.product_barcode_section_wrapper .print_section_wrapper {
	margin-top: 20px;
	display: inline-block;
}

.barcode_delete_button {
	background-image: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
	margin: auto;
	text-align: center;
	text-transform: capitalize;
	transition: 0.5s;
	background-size: 200% auto;
	color: white;
	border-radius: 3px;
	display: block;
	font-size: 12px;
	font-weight: 400;
	width: 80px;
	height: 25px;
	&:hover {
		background-position: right center;
		color: #fff;
		text-decoration: none;
	}
}

.barcode_submit_button {
	background-image: $home_gradient_primary_button;
	text-align: center;
	text-transform: capitalize;
	transition: 0.5s;
	background-size: 200% auto;
	color: white;
	border-radius: 3px;
	display: flex;
	align-items: center;
	gap: 5px;
	justify-content: center;
	font-size: 12px;
	font-weight: 500;
	width: auto;
	height: 32px;
	padding: 0 10px;
	max-width: max-content;
	&:hover {
		background-position: right center;
		color: #fff;
		text-decoration: none;
	}
	&:disabled {
		background: gray !important;
		text-align: center;
		text-transform: capitalize;
		color: white;
		border-radius: 3px;
		display: flex;
		align-items: center;
		gap: 5px;
		justify-content: center;
		font-size: 12px;
		font-weight: 500;
		width: auto;
		height: 32px;
		padding: 0 10px;
	}
}

.inventory-section {
  .inventory-top-box {
    padding: 5px;
    .inventory-box-wrapper {
      padding: 10px;
      // border: 1px solid rgb(212, 210, 210);
      text-align: center;
      color: white;
      background-image: $gradient-primary;
      border-radius: 10px;
      h6 {
        font-size: 12px;
        font-weight: 600;
        display: inline-block;
        position: relative;
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: rgb(214, 210, 210);
          position: absolute;
          bottom: -10px;
          right: 0;
        }
      }
      .inventory-box-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        .inventory-box-info-count {
          font-size: 14px;
        }
      }
    }
  }
  .inventory-datatable-title {
    margin-top: 20px;
    h6 {
      text-align: center;
      color: #000;
    }
  }
}
.inventory-product-add-section {
  padding-top: 20px;
  .inventory-product-add-title {
    text-align: center;
    color: black;
  }
  .category-add-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    .add-category-button {
      padding: 3px 15px;
      background-image: $gradient-primary;
      color: white;
      border-radius: 5px;
      &:hover {
        background-image: $gradient-primary-hover;
      }
    }
  }

  .category-list {
    margin-top: 10px;
  }
}
.category-delete-button {
  color: gray;
  &:hover {
    color: red;
  }
}
.product-add-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .product-add-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 3px 15px;
    background-image: $gradient-primary;
    color: white;
    border-radius: 5px;
    &:hover {
      background-image: $gradient-primary-hover;
    }
  }
  .search-box {
    align-items: flex-end;
  }
}
.add-product-form {
  margin-top: 30px;
}
.add-product-submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    padding: 5px 20px;
    background-image: $gradient-primary;
    color: white;
    border-radius: 5px;
    &:hover {
      background-image: $gradient-primary-hover;
    }
  }
}

.about_section {
	z-index: -2;
	position: relative;
	&::before {
		position: absolute;
		content: '';
		left: 0px;
		width: 150px;
		height: 180px;
		border-radius: 50%;
		background-color: #41acaa41;
		top: 50%;

		transform: translateX(-55%);
	}
	.about_top {
		background-color: #eef5ff;
		// background-color: red;
		padding: 80px 0;
		.about_card_main_wrapper {
			margin-top: 50px;

			.about_card_wrapper {
				display: flex;
				align-items: center;
				gap: 30px;
				.about_card_image_wrapper {
					position: relative;
					height: 450px;
					width: 100%;
					min-width: 550px;
					max-width: 550px;
					img {
						object-fit: contain;
						object-position: left;
					}
				}
				.about_item_wrapper {
					display: flex;
					flex-direction: column;
					gap: 25px;
					.about_item {
						.item_title {
							font-size: 36px;
							font-weight: 500;
							text-transform: capitalize;
						}
						.item_dec {
							font-size: 16px;
							color: $home_paragraph_color;
						}
					}
				}
			}
		}
	}
	// about card
}
.about_sectio_info_wrapper {
	margin: 0 0 80px 0;
	z-index: 3;

	.about_info_card_wrapper {
		margin-top: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		.about_image_wrapper {
			position: relative;
			height: 400px;
			width: 100%;
			min-width: 500px;
			max-width: 500px;

			img {
				object-fit: contain;
				object-position: left;
			}
		}
		.about_info {
			// padding: 20px;

			h1 {
				font-size: 42px;
				font-weight: 500;
				margin: 10px 0;
			}
			p {
				font-size: 16px;
				font-weight: 400;
				color: $home_paragraph_color;
				text-align: justify;
				margin: 30px 0 25px 0;
			}
		}

		&:nth-child(even) {
			display: flex;
			flex-direction: row-reverse;
			.about_image_wrapper {
				position: relative;
				height: 400px;
				width: 100%;
				min-width: 500px;
				max-width: 500px;

				img {
					object-fit: contain;
					object-position: right;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.about_section {
		.about_top {
			background-color: #eef5ff;
			padding: 50px 0 20px 0;
			.about_card_main_wrapper {
				margin-top: 30px;

				.about_card_wrapper {
					display: flex;

					flex-direction: column;
					gap: 30px;

					.about_card_image_wrapper {
						height: 280px;

						min-width: 100%;

						img {
							object-fit: contain;
							object-position: center;
						}
					}
					.about_item_wrapper {
						display: flex;
						flex-direction: column;
						gap: 5px;
						.about_item {
							.item_title {
								font-size: 24px;
								font-weight: 500;
								margin: 10px 0;
							}
							.item_dec {
								font-size: 13px;
								margin: 10px 0;
							}
						}
					}
				}
			}
		}
		// about card
	}

	.about_sectio_info_wrapper {
		margin: 50px 0;

		.about_info_card_wrapper {
			flex-direction: column !important;

			.about_image_wrapper {
				position: relative;
				height: 200px;
				width: 100%;

				img {
					object-fit: contain;
					object-position: center;
				}
			}
			.about_info {
				padding: 0 10px;
				margin-top: 0px;

				h1 {
					font-size: 24px;
					font-weight: 500;
					margin: 10px 0;
				}
				p {
					font-size: 13px;
					margin: 20px 0 15px 0;
				}
			}

			&:nth-child(even) {
				.about_image_wrapper {
					position: relative;
					height: 200px;
					width: 100%;

					img {
						height: 200px;
						width: 100%;
						object-fit: contain;
						object-position: center;
					}
				}
			}
		}
	}
}

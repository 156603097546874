// dashboard sidebar section
.dashboard_setting_layout {
	padding: 10px;
}
.dashboard_setting_content {
	max-width: 1000px;
	margin: auto;
}

.dashboard_setting_sidebar {
	margin: auto;
	padding: 20px;
	border-radius: 5px;
	width: 500px;
	box-shadow: rgba(96, 8, 114, 0.205) 0px 1px 4px;

	.sidebar_list_item_wrapper {
		.setting_section_title {
			font-size: 32px;
			margin-bottom: 20px;
			text-align: center;
		}
		.sidebar_list_item {
			width: max-content;
			cursor: pointer;
			margin: 5px 0;
			padding: 5px 10px;
			border-radius: 3px;
			display: flex;
			align-items: center;

			gap: 20px;
			.menu_title_wrapepr {
				display: flex;
				flex-direction: column;

				.menu_title {
					margin: 0;
					font-weight: 700;
					font-size: 16px;
				}
				.menu_subtitle {
					margin: 0;
					font-weight: 500;
					font-size: 14px;
				}
			}

			&:hover {
				.menu_title {
					color: $title-color;
				}
			}

			&:first-child {
				margin: 0;
			}
		}

		& .active {
			background-image: $gradient-primary !important;
			color: white;
		}
	}
}

.ant-layout-header {
	background-image: $gradient-primary !important;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px !important;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	line-height: 24px;
	.dashboard-header-profile-widgets {
		display: flex;
		align-items: center;
		gap: 30px;

		.dashboard-header-profile-widgets-wallet {
			position: relative;
			&::after {
				position: absolute;
				content: '';
				width: 1px;
				height: 100%;
				background-color: rgb(212, 208, 208);
				top: 0;
				right: -16px;
			}
			.dashboard-header-profile-widgets-wallet-item {
				display: flex;
				flex-direction: column;
				line-height: 8px;
				align-items: flex-end;
				p {
					padding: 0;
					margin: 0 0 8px 0;
					font-size: 12px;
					font-weight: 600;
					text-transform: capitalize;
				}
				span {
					padding: 5px 8px;
					color: teal;
					background-color: #e2dfdf;
					border-radius: 10px;
					font-size: 11px;
					font-weight: 600;
					display: flex;
					align-items: center;
					gap: 5px;
				}
			}
		}
	}
}
.header-info {
	display: flex;
	align-items: center;
	gap: 20px;
	color: white;
	.cols-logo {
		margin-left: -42px;

		.cols-button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 5px 6px;
			cursor: pointer;
			font-size: 16px;
			background-image: $gradient-button;
			border-radius: 5px;
		}
	}
	h5 {
		margin: 0;
		font-size: 20px;
		text-transform: uppercase;
		// margin-left: -35px;
	}
}

.dashboard-header-author-info {
	user-select: none;
	.custom-dropdown {
		position: relative;
		.dropdown-header {
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 15px;
			min-width: 210px;
			.dropdown_avatar_info_wrapper {
				display: flex;
				gap: 5px;
				align-items: center;
				line-height: 12px;
				.avatar_icon {
					border: 1px solid $sub-text-color;
					padding: 3px;
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				}
				p {
					font-size: 13px;
					margin: 0;
					color: white;
				}
			}
		}

		.dropdown_list {
			list-style: none;
			padding: 10px;
			margin: 0;
			position: absolute;
			top: 41px;
			left: 0;
			width: 100%;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			background-color: white;

			.dropdown_list_top_info {
				display: flex;
				align-items: center;
				gap: 5px;
				width: 100%;
				padding: 2px 0px;
				.avatar_icon {
					border: 1px solid #e8e8e8;
					padding: 3px;
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				}

				.dropdown_avatar_info_wrapper {
					display: flex;
					flex-direction: column !important;
					gap: 4px;
					line-height: 12px;
					p {
						font-size: 13px;
						margin: 0;
					}
					span {
						font-size: 11px;
						color: $sub-text-color;
						font-weight: 700;
					}
				}
			}
			.subscription_package_wrapper {
				background: $gradient-secondary;

				color: white;
				padding: 0px 0 5px 10px;
				border-top-left-radius: 10px;

				p {
					font-size: 12px;
					font-weight: 600;
					margin: 0;
				}
			}

			li {
				padding: 6px 5px;

				cursor: pointer;
				.list_item_wrapper {
					display: flex;
					align-items: center;
					gap: 8px;
					p {
						margin: 0;
					}
				}
				&:hover {
					background-color: #f5f5f5;
				}
				&:first-of-type {
					margin-top: 1px;
					border-top: 1px solid $devider-color;
				}
				&:last-of-type {
					// margin-top: 10px;
					border-top: 1px solid $devider-color;
				}
			}
		}
	}
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import 'variables';

@font-face {
	font-family: 'Pragmatica ExtraLight Reg';
	font-style: normal;
	font-weight: normal;
	src: local('Pragmatica ExtraLight Reg'), url('/fonts/Pragmatica-ExtraLight.woff') format('woff');
}

//Landing Page
@import './landing-page/header';
@import './landing-page/hero';
@import './landing-page/about';
@import './landing-page/features';
@import './landing-page/testimonials';
@import './landing-page/sponsor';
@import './landing-page/newsletter';
@import './landing-page/chooseus';
@import './landing-page/footer';
@import './landing-page/blog-section';

//pages
@import './pages/page-breadcrumb';
@import './pages/price';
@import './pages/contact';
@import './pages/auth';
@import './pages/about';
@import './pages/features';
@import './pages/empty-page';
@import './pages/blog';
@import './pages/blog-details';

//Dashboard
@import './dashboard/dashboard';
@import './dashboard/header';
@import './dashboard/sidebar';

@import './dashboard/inventory';
@import './dashboard/service';
@import './dashboard/pos';
@import './dashboard/pos-secondary';
@import './dashboard/invoice';
@import './dashboard/invoice2';
@import './dashboard/product-profile';
@import './dashboard/billing';
@import './dashboard/barcode';
//setting
@import './dashboard/setting/dashboard-setting';
@import './dashboard/setting/shop-setting';
@import './dashboard/setting/profile';
@import './dashboard/setting/team-access';
//user onboard
@import './onboard/onboard';

a,
a:hover,
a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus {
	outline: 0 solid !important;
	text-decoration: none;
}

a {
	-webkit-transition: 0.3s;
	transition: 0.3s;
	color: inherit;
}

a:hover {
	color: inherit;
}

button {
	-webkit-transition: 0.3s;
	transition: 0.3s;
	background: transparent;
	border: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

img {
	max-width: 100%;
	height: auto;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 15px;
	font-weight: 700;
}

*:disabled {
	cursor: not-allowed !important;
}

html {
	overflow-x: hidden;
}
.no_scroll {
	overflow-y: hidden;
}

body {
	overflow-x: hidden;
	font-size: 15px;
	line-height: 24px;
	font-weight: 400;
	font-family: 'Poppins', sans-serif;
	background-color: #fefefe !important;
	// font-family: 'Inter', sans-serif;
	// font-family: 'Nunito Sans', sans-serif;
}

* {
	scrollbar-color: #444 #f1f1f1;
	scrollbar-width: none;
}

::-webkit-scrollbar {
	width: 0px;
	height: 1px;
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background-color: #444;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #333;
}

::-moz-selection {
	background: #1d1d1d;
	color: #fff;
}

::selection {
	background: #1d1d1d;
	color: #fff;
}

/* CSS START */
.table-icon {
	width: 50px;
	height: 30px;
	object-fit: contain;
	background-color: transparent;
	border-radius: 2px;
}

.otp-timer-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	padding: 10px 5px;

	.resend-text {
		cursor: pointer;
		color: $primary-color;
		font-weight: 700;
	}
}

//ant design global class customize
.ant_image_override {
	width: 100%;
	height: auto;
	vertical-align: middle;
	object-fit: contain;
}
.ant-table-wrapper {
	clear: both;
	max-width: 100%;
	margin-top: 10px;
}

// .ant-form-item-control-input-content {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }
.site-form-item-icon {
	font-size: 12px;
}
.ant-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.ant-btn-icon-only {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.ant-table-cell {
	padding: 5px 10px !important;
}

.ant-dropdown {
	top: 50px !important;
}

.ant-menu-horizontal {
	line-height: 32px !important;
	background-color: $bg-color !important;

	.nav-text {
		font-size: 12px;
	}
}

.ant-form-item {
	margin-bottom: 15px;
}

.otp-content-area {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.from-content {
		padding: 10px;

		background: white;

		.image-area {
			padding: 15px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.form-image-wrapper {
				margin: 10px 0;
			}

			.image-area-desc {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				h6 {
					font-size: 15px;
					margin-bottom: 5px;
				}

				p {
					font-size: 12px;
				}

				a {
					font-size: 12px;
					color: $blue;
				}
			}
		}

		.form-area {
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
			border-radius: 10px;
			padding: 20px;
			color: black;

			.from-area-wrapper {
				max-width: 500px;
				padding: 5px;
				margin: auto;

				.form-title {
					text-align: center;
					margin-bottom: 20px;

					h2 {
						font-size: 20px;
						margin-bottom: 10px;
						text-transform: capitalize;
					}

					p {
						font-size: 14px;
						color: black;
						text-transform: capitalize;

						span {
							cursor: pointer;
							font-weight: 600;
							margin-left: 5px;
						}
					}
				}

				.otp-section {
					.sign-up-button-wrapper {
						.sign-up-button {
							padding: 2px 20px;
							background-color: $primary-color;
							color: white;
							border-radius: 10px;
						}
					}
				}
			}
		}
	}
}

.otp_content_wrapper_main {
	.ant-form-item-control-input-content {
		display: flex;
		align-items: center;
		justify-content: center !important;
		.otp-input-section {
			display: flex;
			align-items: center;
			justify-content: center;

			input {
				width: 34px !important;
				height: 35px;
				border: none;
				color: $primary-color;
				font-weight: 700;
				border: 1px dashed $primary-color;
				margin-left: 3px;
				margin-right: 3px;
			}
		}
	}
}

.ant-form-item-control-input-content {
	.otp-input-section {
		display: flex;
		align-items: center;
		justify-content: center;

		input {
			width: 35px;
		}
	}
}

// dashboard-report-section
.dashboard-report-container {
	.report-filter-section {
		padding: 10px;
		background-color: #f1f1f1;
		margin-bottom: 10px;
	}
}

.pdf-downlode-button {
	margin-left: 20px;
	line-height: 0 !important;
}

.purchase-tag {
	text-transform: uppercase;
	color: #fff;
	border-radius: 50px;
	background-image: linear-gradient(145deg, #b224ef 0%, #7579ff 100%);
}
.sale-tag {
	text-transform: uppercase;
	color: #fff;
	border-radius: 50px;
	background-image: linear-gradient(145deg, #ff5858 0%, #f09819 100%);
	box-shadow: 4px 5px 15px -3px rgba(0, 0, 0, 0.1);
}
.row_devider {
	background-color: $devider-color;
	height: 1px;
	width: 100%;
	margin: 10px 0;
}
.col_devider {
	background-color: $devider-color;
	width: 1px;
	height: 100%;
	margin: 0 10px;
}
.ant-layout {
	background-color: $bg-color;
}

.flex_center {
	display: flex;
	align-items: center;
	gap: 5px;
}
.flex_between {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}
.global_icon_wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}
.global_button {
	background-image: $gradient-primary;
	color: white;
	padding: 8px 10px;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 600;
}
.button_gradiend_primary {
	background: $gradient-secondary;
	padding: 5px 30px;
	color: white !important;
	text-transform: capitalize;
	font-weight: 600;
	border-radius: 3px;
	border: none;
	font-size: 14px;
	transition: all 0.5s ease-out 0s;
	background-size: 200%;
	cursor: pointer;
	width: 100%;
	height: 40px;
	&:hover {
		background-position: right center;
	}
}
.custom_primary {
	background: $gradient-primary;
	padding: 5px 15px;
	color: white !important;
	text-transform: capitalize;
	font-weight: 600;
	border-radius: 5px;
	border: none;
	font-size: 14px;
	transition: all 0.5s ease-out 0s;
	background-size: 200%;
	cursor: pointer;
	height: 32px;
	&:hover {
		background-position: right center;
	}
}
.custom_primary_button {
	background: $gradient-primary;
	padding: 5px 15px;
	color: white !important;
	text-transform: capitalize;
	font-weight: 600;
	border-radius: 5px;
	border: none;
	font-size: 14px;
	transition: all 0.5s ease-out 0s;
	background-size: 200%;
	cursor: pointer;
	height: 32px;
	&:hover {
		background-position: right center;
	}
}

.pdf_downloader_button_wrapper {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

// income report heder start
.income_report_header_wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.custom_card_title {
		margin: 0;
	}
	.report_action_wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
	}
}
.report_date_wrapper {
	padding: 0 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
	h6 {
		margin: 0;
		font-weight: 500;
	}
	.report_date {
		display: flex;
		align-items: center;
		gap: 10px;
		p {
			margin: 0;
		}
	}
}
.report_custom_table_wrapper {
	table {
		padding: 10px;
		width: 50%;
		thead {
			tr {
				th {
					padding: 8px 10px;
				}
			}
		}
		tbody {
			tr {
				td {
					padding: 8px 10px;
				}
				&:last-child {
					font-size: 14px;
					font-weight: 700;
				}
			}
		}
	}
}
// income report heder end

// landing page global css start
.landing_primary_button {
	background-image: $home_gradient_primary_button;
	cursor: pointer;
	text-align: center;
	text-transform: capitalize;
	transition: 0.5s;
	background-size: 200% auto;
	color: white;
	border-radius: 8px;
	display: block;
	font-size: 16px;
	font-weight: 400;
	padding: 5px 15px;
	&.large_button {
		width: 143px;
		height: 48px;
	}
	&.mid_button {
		width: 137px;
		height: 39px;
	}
	&.small_button {
		width: 100px;
		height: 39px;
	}
	&:hover {
		background-position: right center;
		color: #fff;
		text-decoration: none;
	}
}

.landing_primary_button_border {
	border: 2px solid #325aab;

	padding: 5px 15px;
	text-align: center;
	text-transform: capitalize;
	transition: 0.5s;
	background-size: 200% auto;
	color: #325aab;
	border-radius: 8px;
	display: block;
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	&.small_button {
		width: 100px;
		height: 39px;
	}
	&:hover {
		background-image: $home_gradient_primary_button;
		color: #fff;
		background-position: right center;
		text-decoration: none;
		border: 2px solid transparent;
	}
}
.color_title {
	font-weight: inherit;
	color: #41acaa;
}

// landing page global css end
.page_section_wrapper {
	margin-top: 60px;
}
.container {
	margin: 0 auto;
	padding: 0 10px;
	max-width: 1280px;
	width: 100%;
}
.pos-container {
	margin: 0 auto;
	padding: 0 10px;
	max-width: 1350px;
	width: 100%;
}
@media only screen and (min-width: 1500px) {
	.pos-container {
		margin: 0 auto;
		padding: 0 10px;
		max-width: 1500px;
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.page_section_wrapper {
		margin-top: 50px;
	}
	.landing_primary_button {
		font-size: 12px;
	}
	.landing_primary_button_border {
		font-size: 12px;
	}
}

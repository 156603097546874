// dashboard design  area

.dashboard_layout_wrapper {
	.dashboard_header_section_wrapper {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1000;
	}
	.dashboard_main_container {
		.dashboard_sidebar_section_wrapper {
			width: 200px;
			position: fixed;
			left: 0;
			top: 50px;
			background-color: white;
			z-index: 100;
			height: calc(100vh - 50px);
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			&.section_fold {
				width: 40px !important;
			}
		}

		.dashboard_content_section_wrapper {
			padding: 5px;
			margin-top: 50px;
			width: calc(100% - 200px);
			margin-left: 200px;
			min-height: calc(100vh - 52px);

			&.section_fold {
				width: calc(100% - 40px);
				margin-left: 40px !important;
			}
		}
	}
}

.dashboard-container {
	padding: 5px;
}
.breadcrubm_wrapper {
	padding: 10px 0;
}
.dashboard-section {
	.dashboard-full-wide {
		background-color: rgb(241, 247, 241);
	}
}

// .dashboard-content-area {
// 	padding: 5px 20px;
// 	width: 100%;

// 	display: flex;
// 	align-items: flex-start;
// 	justify-content: center;
// }

.dashboard-nav {
	padding: 10px 0;
}
.dashboard-nav-button {
	display: flex;
	align-items: center;
	gap: 20px;
	margin: 0 0 0 12px !important;
	h6 {
		margin: 0;
		font-size: 26px;
	}
}

.dashboard-widget-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.dashboard-widget-wrapper-item {
	padding: 10px;
	margin-top: 10px;

	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.dashboard-widget-item-wrapper {
	width: 100%;
	min-height: 130px;
	margin: 2px 0;
	border: 1px solid $sub-text-border-color;
	// box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	// color: white !important;
	// background-image: $gradient-primary;
	border-radius: 10px;
	padding: 10px;
	color: $price-title-color;
	.item-title {
		border-bottom: 1px solid $devider-color;
		h5 {
			font-size: 20px;
			margin: 5px 0 !important;
		}
	}
	.item-count {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;

		h6 {
			margin: 5px 0 !important;
			font-size: 16px;
		}
		.dashboard_widget_item_image_wrapper {
			position: relative;
			width: 60px;
			height: 60px;
			padding: 7px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 4px double $sub-text-border-color;


			border-radius: 50%;
			/* Halve the circle */
			border-right-color: transparent;
			border-left-color: transparent;
			/* Rotate the circle */
			transform: rotate(-45deg);
		}
	}
}

.page-container {
	padding-left: 0.5em;

	.ant-breadcrumb {
		padding-left: 1em;
		padding-top: 1em;
		padding-bottom: 1em;
	}
}

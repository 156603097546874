.auth_page_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh !important;
	width: 100vw !important;
	background-color: $hero_bg;
	position: relative;
	z-index: 1;
	padding: 10px;
	&::before {
		z-index: -1;
		position: absolute;
		content: '';
		bottom: 0;

		left: 0;
		width: 100%;
		height: 70%;
		background-image: url('/images/page/auth/vector-bg1.png');
		background-repeat: no-repeat;
		background-position: left;
		background-size: contain;
	}
	&::after {
		z-index: -1;
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-image: url('/images/page/auth/vector-bg2.png');
		background-repeat: no-repeat;
		background-position: right;
		background-size: contain;
	}
}
.auth_page_wrapper .auth_form_content_wrapper {
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 10px;
	padding: 40px 30px;
	text-align: center;
	background-color: white;
	min-width: 420px;
	max-width: 500px;
	width: 100%;
	user-select: none;

	.auth_header_image_wrapper {
		position: relative;
		height: 40px;
		width: 180px;
		cursor: pointer;
		margin: auto;
	}
	.auth_form_wrapper {
		margin-top: 20px;

		width: 100%;

		//auth from global
		.auth_from_content {
			.auth_from_title {
				margin-bottom: 20px;
				h2 {
					font-size: 24px;
				}
			}
			.submit_button {
				margin-top: 10px;
				background: $home_gradient_primary_button;
				color: white;
				height: 38px;
				border: none;
				transition: 0.5s;
				background-size: 200% auto;
				&:hover {
					border: none;
					background-position: right center;
					color: #fff;
					text-decoration: none;
				}
				&:disabled {
					background: linear-gradient(112.1deg, rgb(69, 70, 71) 20%, rgb(232, 233, 236) 100%);
					cursor: not-allowed;
				}
			}

			.login-form-forgot {
				color: $title-color;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.from_social_login_area {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 20px;
			.from_social_login_button {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				padding: 8px 10px;
				border: 1px solid rgb(230, 227, 227);
				height: 40px;
			}
			.after_devider {
				margin: 30px 0;
				position: relative;
				background-color: rgb(223, 218, 218);
				height: 1px;
				width: 100%;

				.after_devider_text {
					font-size: 12px !important;
					color: gray;
					padding: 0 10px;
					position: absolute;
					background-color: white;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -62%);
				}
			}
		}
		.back_register {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-top: 15px;
			font-size: 14px;
			p {
				margin: 0;
				font-weight: 600;
			}
			a {
				color: $title-color;
				&:hover {
					color: $blue;
					text-decoration: underline;
				}
			}
		}
		//sign-in form section
		//sign-up form section
		//otp form section
		//email sent form section
	}
}
.auth_page_wrapper .auth_banner_content_wrapper {
	flex: 1;
	height: 100%;
	padding: 20px;
	background: radial-gradient(circle, rgb(132, 127, 214) 0%, rgb(55, 47, 158) 100%);
	color: white;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.auth_banner_image_section_wrapper {
		position: relative;
		width: 100%;
		height: 40%;
		margin: 10px auto;
	}
	.auth_banner_header_section_wrapper {
		h5 {
			font-size: 26px;
		}
	}
	.auth_banner_footer_section_wrapper {
		h5 {
			font-size: 26px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.auth_page_wrapper {
		height: 100% !important;
	}
	.auth_page_wrapper .auth_form_content_wrapper {
		min-width: 100%;

		.auth_header_image_wrapper {
			position: relative;
			height: 40px;
			width: 180px;
			cursor: pointer;
			margin: auto;
		}
		.auth_form_wrapper {
			margin-top: 20px;

			width: 100%;

			//auth from global
			.auth_from_content {
				.auth_from_title {
					margin-bottom: 20px;
					h2 {
						font-size: 24px;
					}
				}
				.submit_button {
					margin-top: 10px;
					background: $home_gradient_primary_button;
					color: white;
					height: 38px;
					border: none;
					transition: 0.5s;
					background-size: 200% auto;
					&:hover {
						border: none;
						background-position: right center;
						color: #fff;
						text-decoration: none;
					}
					&:disabled {
						background: linear-gradient(112.1deg, rgb(69, 70, 71) 20%, rgb(232, 233, 236) 100%);
						cursor: not-allowed;
					}
				}

				.login-form-forgot {
					color: $title-color;
					font-size: 12px;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			.from_social_login_area {
				.from_social_login_button {
					height: 35px;
				}
				.after_devider {
					margin: 20px 0;

					.after_devider_text {
						font-size: 10px !important;
						line-height: normal;

						transform: translate(-50%, -50%);
					}
				}
			}
			.back_register {
				display: flex;
				align-items: center;
				gap: 10px;
				margin-top: 15px;
				font-size: 11px;
			}
		}
	}
}
@media only screen and (min-width: 2560px) and (max-width: 4000px) {
}

.contact_top_section {
	padding: 50px 0px;
	height: 65vh;
	position: relative;
	background-image: url('/images/page/contact/contact-bg.png');
	background-repeat: no-repeat;
	z-index: 1;
	background-position: center;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	&::after {
		position: absolute;
		content: '';
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: -1;
		background: #325aab94;
		// backdrop-filter: blur(1px);
	}
	.section_title {
		max-width: 700px;
		text-align: center;
		margin: auto;
		font-size: 74px;
		color: white;
		font-weight: 600;
	}
}
.contact_section_wrapper {
	background-color: white;
	padding: 50px 10px;

	.contact_title {
		margin: 0;
		text-align: center;
		font-size: 48px;
		font-weight: 600;
	}
	.contact_content_wrapper {
		display: flex;
		padding: 0 10px;
		gap: 20px;
		margin-top: 50px;

		.contact_form_wrapper {
			flex: 1;
			padding: 20px;
			border-radius: 10px;

			.ant-form-item {
				margin: 20px 0 0 0;
				&:first-child {
					margin: 0;
				}
				.ant-form-item-control-input {
					min-height: 40px;
					.ant-input {
						border-width: 2px;
						line-height: 2.5;
						&::placeholder {
							font-size: 16px !important;
							color: rgb(177, 174, 174);
						}
						&:focus {
							border-color: $home_primary_color !important;
							box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
							border-width: 2px;
						}
						&:hover {
							border-color: $home_primary_color !important;
						}
					}
				}
			}
		}

		.contact_info_wrapper {
			flex: 1;
			padding: 10px;
			max-width: 450px;

			.contact_question_wrapper {
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				padding: 20px 10px;
				text-align: center;
				border-radius: 15px;
				.contact_question_title {
					font-size: 40px;

					font-weight: 500;
				}
				.contact_question_button {
					background-image: linear-gradient(to bottom, $home_primary_color 20%, $home_secondary_color 100%);

					text-align: center;
					text-transform: capitalize;
					transition: 0.5s;
					background-size: 200% auto;
					color: white;
					border-radius: 8px;
					display: block;
					font-size: 14px;
					font-weight: 400;
					display: block;
					margin: auto;
					padding: 10px 20px;
				}
				.contact_question_subtitle {
					margin-top: 10px;
					font-size: 14px;
					color: $home_paragraph_color;
				}
			}
			.contact_info_item_section {
				margin-top: 30px;

				.contact_info_item_title {
					font-size: 42px;
					font-weight: 500;
				}
				.contact_info_item_description {
					font-size: 20px;
					color: $home_paragraph_color;
					line-height: normal;
					margin-bottom: 30px;
				}
				.contact_info_item {
					display: flex;
					align-items: center;
					gap: 10px;
					margin-top: 10px;
					p {
						margin: 0;
						font-weight: 600;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	.contact_top_section {
		padding: 20px 0px;
		height: 40vh;

		.section_title {
			font-size: 36px;
		}
	}

	.contact_section_wrapper {
		background-color: white;
		padding: 10px;

		.contact_title {
			font-size: 30px;

			margin-top: 20px;
		}
		.contact_content_wrapper {
			padding: 0 !important;
			flex-direction: column-reverse;

			.contact_form_wrapper {
				padding: 20px 0px;

				.ant-form-item {
					margin: 20px 0 0 0;
					&:first-child {
						margin: 0;
					}
					.ant-form-item-control-input {
						.ant-input {
							line-height: 2;
							&::placeholder {
								font-size: 14px !important;
							}
						}
					}
				}
			}

			.contact_info_wrapper {
				padding: 0px;

				.contact_question_wrapper {
					padding: 10px;

					.contact_question_title {
						font-size: 26px;

						font-weight: 500;
					}
					.contact_question_button {
						font-size: 12px;
						font-weight: 400;
						display: block;
						margin: auto;
						padding: 5px 15px;
					}
					.contact_question_subtitle {
						margin-top: 5px;
						font-size: 12px;
						color: $home_paragraph_color;
					}
				}
				.contact_info_item_section {
					.contact_info_item_title {
						font-size: 26px;
					}
					.contact_info_item_description {
						font-size: 14px;
						color: $home_paragraph_color;
						line-height: normal;
						margin-bottom: 20px;
					}
					.contact_info_item {
						display: flex;
						align-items: flex-start;
						gap: 10px;
						margin-top: 10px;
						p {
							font-size: 14px;
							margin: 0;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}

.shop_setting_section {
	padding: 20px 10px;

	.shop_setting_section_title {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 700;

		h5 {
			margin: 0;
		}

		.title {
			a {
				display: flex;
				align-items: center;
			}

			a:hover {
				color: blue;
			}
		}

		.dashboard_profile_section_title_checkbox_label {
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 7px 15px;
			border-radius: 10px;
			font-size: 14px;
			border: 1px solid rgb(238, 235, 235);

			h5 {
				margin: 0;
				font-size: 14px;
			}
		}

		.dashboard_profile_section_title_checkbox {
			display: none;
		}
	}
	.dashboard_shop_setting {
		.shop_select_radio_custom {
			.ant-radio-group {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				// flex-wrap: wrap;
				overflow-x: scroll;
				padding-bottom: 20px;

				&::-webkit-scrollbar {
					display: block;
					padding: 10px;
					height: 8px;
				}
				&::-webkit-scrollbar-track {
					background: transparent;
				}

				&::-webkit-scrollbar-thumb {
					background-image: $gradient-primary;
					border-right: none;
					border-left: none;
				}

				&::-webkit-scrollbar-track-piece:end {
					background: rgb(247, 247, 247);
					margin-bottom: 10px;
				}

				&::-webkit-scrollbar-track-piece:start {
					background: rgb(247, 247, 247);
					margin-top: 10px;
				}
			}
			.ant-radio-button-wrapper {
				overflow: hidden;
				border-radius: 10px !important;
				min-height: 90px !important;
				min-width: 100px !important;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px !important;
				height: max-content;
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				border: none;
				border-block-start-width: 0 !important;
				border-inline-start-width: 0 !important;
				border-inline-end-width: 0 !important;
				&::before {
					width: 0 !important;
				}
			}
			.ant-radio-button-wrapper-checked {
				color: white;
				&:not(.ant-radio-button-wrapper-disabled) {
					color: white;
					&:hover {
						color: white;
					}
				}
			}
			.ant-radio-button-checked {
				background-image: $gradient-primary;
			}
			.ant-radio-button {
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			}
		}
		.shop_setting_button_wrapepr {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			.shop_setting_button {
				padding: 5px 10px;
				background-image: $gradient-primary;
				color: white;
				font-size: 14px;
				font-weight: 600;
				border-radius: 5px;

				&:hover {
					background-image: $gradient-primary-hover;
				}
			}
		}
	}
	.select_shop_card_wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 8px;
		text-align: center;
		span {
			font-size: 14px;
			font-weight: 600;
			line-height: 15px;
		}
	}
}

// color
$blue: #005bd4;
$sky-blue: #005bd4;
$primary-color: #4e37b2;
$secondary-color: #396bd8;
$title-color: #4088c0;
$sub-text-color: #68afe2;
$sub-text-border-color: #b0c9d8;
$label-text-color: #68a3ca;
$price-title-color: #164b77;

$devider-color: rgb(235, 231, 231);

$gradient-primary: linear-gradient(to right, $primary-color, $secondary-color);
$gradient-secondary: linear-gradient(to right, $title-color, $sub-text-color);
// $gradient-secondary: linear-gradient(to right, rgba($title-color, 1), rgba($sub-text-color, 0.8));
$gradient-primary-hover: linear-gradient(to left, $primary-color, $secondary-color);
$gradient-button: linear-gradient(to right, rgb(157, 204, 212), rgb(44, 29, 110));

$bg-color: white;
$light-bg-color: #f1f1f5;
$white-text: white;
$light-text-color: rgb(83, 81, 81);
$gray-text-color: #7991a5;

$gradient_primary_button: linear-gradient(to right, $primary-color, $secondary-color);

// landing page variable
$home_primary_color: #36c2c0;
$home_secondary_color: #325aab;
$home_title_color: #315ba7;
$home_paragraph_color: #575757;
$home_gradient_primary_button: linear-gradient(to right, $home_primary_color 0%, $home_secondary_color 51%, $home_primary_color 100%);

$header_bg: #f5f8ff;
$hero_bg: #e4edff;
$secondary_bg: #e4edff;

$home_gradient_border: linear-gradient(to right, $home_primary_color 20%, $home_secondary_color 100%);

// 1st dashboard theme color plate
// $primary_color: #36c2c0;
// $secondary_color: #325aab;
// $gradient_primary_color: linear-gradient(to right, $home_primary_color 0%, $home_secondary_color 51%);
// $gradient_primary_buttton_color: linear-gradient(to right, $home_primary_color 0%, $home_secondary_color 51%, $home_primary_color 100%);
// $primary_title_color: #36c2c0;
// $secondary_title_color: #36c2c0;
// $primary_subtitle_color: #36c2c0;

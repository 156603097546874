.empty_page_wrapper {
	margin-top: 80px;
	height: 42vh;
	.empty_card {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.empty_card_image {
			position: relative;
			width: 250px;
			height: 250px;
			display: flex;
			align-items: center;
			justify-content: center;

			.empty_card_title {
				position: absolute;
				bottom: -50px;
				text-align: center;
				width: 100%;
				color: $gray-text-color;
				font-size: 14px;
			}
		}
	}
}

.feature_section_wrapper {
	margin-top: 65px;
	display: flex;
	gap: 5px;
	user-select: none;

	.feature_sidebar_wrapper {
		padding: 10px;
		min-width: 270px;
		max-width: 270px;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

		.feature_sideber_title_wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;

			h5 {
				font-size: 24px;
				font-weight: 600;
				margin: 0;
			}
		}
		.nav_submenu_ul {
			margin-top: 20px;
			display: flex;
			flex-direction: column;

			&.fold {
				display: none;
			}
			.submenu_list_wrapper {
				background-color: white;

				.sub_menu_item {
					padding: 8px;
					display: flex;
					align-items: flex-start;
					gap: 10px;
					cursor: pointer;

					border-bottom: 1px solid $devider-color;

					&.active {
						background-color: $hero_bg;
						color: $home_title_color;
					}

					&:last-child {
						border: none;
					}
					.list_card_image_wrapper {
						position: relative;
						min-width: 40px !important;
						height: 50px;
					}
					.list_card_title_wrapper {
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						justify-content: flex-start;
						.submenu_title {
							margin-bottom: 5px;
							font-size: 14px;
							font-weight: 500;
						}

						.submenu_subtitle {
							margin: 0;
							font-size: 12px;
							color: $home_paragraph_color;
							text-align: start;
							line-height: 14px;
						}
					}

					a {
						&::after {
							display: none;
						}
					}

					&:hover .list_card_title_wrapper {
						color: $secondary-color !important;
					}
				}
			}
		}
	}

	.feature_item_section_wrapper {
		padding: 20px;
		width: 100%;
		background-color: white !important;
	}
}
@media only screen and (max-width: 768px) {
	.feature_section_wrapper {
		flex-direction: column;
		.feature_sidebar_wrapper {
			min-width: 270px;
			max-width: 400px;
			.feature_sideber_title_wrapper {


				h5 {
					font-size: 18px;
					font-weight: 600;
					margin: 0;
				}
			}

			.nav_submenu_ul {
				margin-top: 20px;
				display: none;

				&.fold {
					display: flex;
					flex-direction: column;
				}
			}
		}

		.feature_item_section_wrapper {
			padding: 10px;
			width: 100%;
			background-color: white !important;
		}
	}
}

.home_blog_section_wrapper {
	padding: 50px 0 50px 0;
	background-color: rgb(230, 236, 250);
}
.home_blog_section_wrapper .home_blog_title_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0;

	.home_blog_title {
		margin: 0;
		font-size: 46px;
		font-weight: 600;
	}
}

.home_blog_section_wrapper .view_more_button_wrapper {
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.home_blog_section_wrapper .home_blog_card_item_wrapper {
	margin-top: 60px;
	display: grid;
	grid-template-columns: auto auto auto;
	grid-row-gap: 50px;
}

.home_blog_section_wrapper .home_blog_card_item_wrapper .home_blog_card {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

	max-width: 380px;
	min-height: 450px;
	min-width: 380px;
	position: relative;
	border-radius: 15px;
	overflow: hidden;
	background-color: white;

	&:hover {
		cursor: context-menu;
		box-shadow: rgba(236, 235, 235, 0.1) 0px 12px 12px;
	}

	.home_blog_card_image_wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		min-height: 250px;
		max-height: 250px;
		z-index: 1;
		&::after {
			position: absolute;
			content: '';

			bottom: -30px;
			width: 100%;
			height: 50px;
			z-index: 1;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.151) 50%, rgba(255, 255, 255, 0.247) 100%);
			backdrop-filter: blur(1px);
		}
		img {
			object-fit: cover;
			object-position: top center;
		}
	}

	.home_blog_info {
		padding: 0 10px;
		margin-top: 30px;
		.home_blog_info_title {
			font-size: 17px;
			font-weight: 600;
			text-transform: capitalize;
			color: $home_title_color;
			cursor: pointer;

			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			-webkit-transition: ease-in-out all 0.5s;
			transition: ease-in-out all 0.5s;
		}
		.home_blog_info_des {
			margin: 0;
			font-size: 14px;
			font-weight: 400;
			color: $home_paragraph_color;
			line-height: normal;

			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			-webkit-transition: ease-in-out all 0.5s;
			transition: ease-in-out all 0.5s;
		}
		.read_button_wrapper {
			margin-top: 10px;
			cursor: pointer;
			.read_button {
				font-size: 12px;
				color: $home_title_color;
				font-weight: 500;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.home_blog_section_wrapper {
		padding: 50px 0;
	}
	.home_blog_section_wrapper .home_blog_title_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.home_blog_title {
			font-size: 26px;
		}
	}
	.home_blog_section_wrapper .home_blog_card_item_wrapper {
		margin-top: 30px;
		display: grid;
		grid-template-columns: auto;
		grid-row-gap: 20px;
	}

	.home_blog_section_wrapper .home_blog_card_item_wrapper .home_blog_card {
		min-height: max-content;
		padding-bottom: 20px;
		max-width: 100%;

		min-width: 100%;
		.home_blog_card_image_wrapper {
			position: relative;
			width: 100%;
			height: 100%;
			min-height: 180px;
			max-height: 180px;
			z-index: 1;
		}
		.home_blog_info {
			padding: 0 10px;
			margin-top: 30px;
			.home_blog_info_title {
				font-size: 16px;
				font-weight: 600;
				text-transform: capitalize;
				color: $home_title_color;
			}
			.home_blog_info_des {
				margin: 0;
				font-size: 12px;
				font-weight: 400;
				color: $home_paragraph_color;
				line-height: normal;
			}
			.read_button_wrapper {
				margin-top: 10px;
				cursor: pointer;
				.read_button {
					font-size: 11px;
					color: $home_title_color;
					font-weight: 500;
				}
			}
		}
	}
}

.hero_section_wrapper {
	background-color: $hero_bg;
	min-height: calc(100vh - 50px);
	max-width: 100%;
	position: relative;
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
	background-image: url('/images/bg/hero-vector-bg.png');
	background-repeat: no-repeat;

	background-position: center;
	background-size: cover;

	&::after {
		position: absolute;
		content: '';
		right: -20px;
		bottom: 20px;
		width: 100%;
		height: 70px;
		background-image: url('/images/bg/hero-vector-bg-dot.png');
		background-repeat: no-repeat;
		z-index: 100;
		background-position: right;
		background-size: contain;
	}
	.bubble {
		content: '';
		border-bottom-left-radius: 50% 100%;
		border-bottom-right-radius: 50% 100%;
		position: absolute;
		bottom: -45px;
		z-index: -1;
		width: 300px;
		background-color: $hero_bg;
		left: 50%;
		height: 300px;
		transform: translateX(-50%);

		&::after {
			content: '';
			position: absolute;
			bottom: 37px;
			right: 65px;
			width: 20px;
			height: 20px;
			background: $hero_bg;
			border-bottom-right-radius: 30px;

			transform: rotate(25deg);
		}
		&::before {
			content: '';
			position: absolute;
			bottom: 37px;
			left: 68px;
			width: 20px;
			height: 20px;
			background: $hero_bg;
			border-bottom-right-radius: 30px;

			transform: rotate(40deg);
		}
	}

	.hero-section-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		.hero_info_area {
			padding: 0 50px 0 0;
			h5 {
				font-size: 60px;
				font-weight: 600;
				margin: 20px 0 30px 0;
			}
			p {
				font-size: 18px;
				font-weight: 400;
				color: $home_paragraph_color;
				text-align: justify;
			}
			// .down-link {
			// 	margin-top: 20px;
			// 	display: flex;
			// 	align-items: center;

			// 	gap: 20px;
			// 	.down-link-item {
			// 		background-color: rgb(245, 242, 242);
			// 		cursor: pointer;
			// 		display: flex;
			// 		align-items: center;
			// 		padding: 10px 20px;
			// 		border: 1px solid #fff;
			// 		border-radius: 5px;
			// 		overflow: hidden;
			// 		font-size: 12px;
			// 		&:hover {
			// 			background-color: rgb(58, 165, 165);
			// 			color: white;
			// 		}
			// 	}
			// }
		}
		.hero_image_wrapper {
			position: relative;
			width: 100%;
			height: 500px;
			min-width: 550px;
			max-width: 550px;
			img {
				object-fit: contain;
				object-position: right;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.hero_section_wrapper {
		margin-top: 50px;
		.hero-section-info {
			display: flex;
			flex-direction: column-reverse;

			.hero_info_area {
				padding: 10px;
				max-width: 100%;
				h5 {
					font-size: 28px;
					margin: 10px 0 20px 0;
					line-height: 40px;
				}
				p {
					font-size: 14px;

					text-align: justify;
				}
			}
			.hero_image_wrapper {
				position: relative;
				width: 100%;
				height: 225px;
				img {
					object-fit: contain;
					object-position: center;
				}
			}
		}
	}
}
@media only screen and (min-width: 1450px) {
	.hero_section_wrapper {
		background-color: $hero_bg;
		min-height: calc(70vh - 50px);
		max-width: 100%;
		position: relative;
		margin-top: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0;
		background-image: url('/images/bg/hero-vector-bg.png');
		background-repeat: no-repeat;

		background-position: center;
		background-size: cover;
	}
}

.testimonials_section_wrapper {
	margin:50px 0 100px 0;
	.top_arrow_slider {
		.slider_top_title_wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 10px 0;
			.slider_top_title {
				margin: 0;
				font-size: 46px;
				font-weight: 600;
			}
			.slider_button_wrapper {
				display: flex;
				align-items: center;

				gap: 15px;

				.slider_button {
					display: flex;
					align-items: center;

					gap: 20px;
					.button_next_slide,
					.button_prev_slide {
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}

		.swiper .swiper-pagination-bullet {
			width: 10px !important;
			height: 10px !important;
		}
		.swiper .swiper-pagination-bullet-active {
			background: $home_secondary_color;
		}
		.swiper .swiper-wrapper {
			width: 100%;
			margin: 60px 0;
		}
		.swiper .swiper-wrapper .swiper-slide {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;
			flex-wrap: wrap;
			// background-color: red;

			.slider_item_card_wrapper {
				display: flex;
				width: 100%;
				max-width: 580px;
				height: 200px;
				overflow: hidden;
				background-color: #e4edff;
				box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
				padding: 20px;
				gap: 10px;
				border-radius: 12px;
				.slide_image_wrapper {
					position: relative;
					min-width: 80px;
					height: 80px;
					cursor: pointer;
					padding: 2px;
					overflow: hidden;
					border-radius: 50%;
				}

				.slide_author_info {
					padding-top: 8px;
					.author_name {
						margin: 0;
						font-size: 22px;
						font-weight: 500;

						color: #6f6c6c;
					}
					.author_address {
						margin: 0;
						font-size: 14px;

						color: #6f6c6c;
					}
					.author_comments {
						margin: 12px 0 0 0;
						font-size: 13px;
						font-weight: 400;
						line-height: 18px;
						color: #6f6c6c;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	.testimonials_section_wrapper {
		margin: 50px 0;
		.top_arrow_slider {
			.slider_top_title_wrapper {
				.slider_top_title {
					font-size: 26px;
				}
			}

			.swiper .swiper-wrapper {
				margin: 20px 0 50px 0;
			}
			.swiper .swiper-wrapper .swiper-slide {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;

				// background-color: red;

				.slider_item_card_wrapper {
					padding: 10px;
					height: max-content;

					.slide_image_wrapper {
						min-width: 60px;
						height: 60px;
					}

					.slide_author_info {
						padding-top: 5px;
						.author_name {
							margin: 0;
							font-size: 18px;
							font-weight: 500;

							color: #6f6c6c;
						}
						.author_address {
							margin: 0;
							font-size: 14px;

							color: #6f6c6c;
						}
						.author_comments {
							margin: 5px 0 0 0;
							font-size: 12px;
							font-weight: 400;
							line-height: 18px;
							color: #6f6c6c;
						}
					}
				}
			}
		}
	}
}

@import 'variables';
@import './dashboard/pos';

/* Full HD - TV Layout: Common */
@media only screen and (min-width: 2200px) and (max-width: 4000px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 26px !important;
	}
	// pos content area
	.dashboard-pos-section {
		.pos-top-menu {
			padding: 10px 20px;
			ul {
				font-size: 20px;
			}
		}
		.pos-info {
			ul {
				font-size: 20px;
			}
		}
		.dashboard-pos-section-container {
			overflow: hidden !important;
			margin: 5px 10px;
			.pos-list-wrapper {
				padding: 5px;
				.pos-product-list-wrapper {
					box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
					.pos-list-input {
						font-size: 18px;
						.ant-select-selector {
							padding: 5px;
							height: 45px;
							font-size: 22px;
							font-weight: 800;
						}
					}
					.pos-table-wrapper {
						max-height: 400px !important;

						table {
							width: 100%;
							height: 100%;

							thead {
								tr {
									padding: 5px 10px;

									th {
										padding: 10px 5px;
										font-size: 24px;
									}
								}
							}
							tbody {
								tr {
									padding: 5px 10px;

									td {
										text-align: center;
										padding: 10px 5px;

										font-size: 22px;

										line-height: 20px;
									}
								}
							}

							.pos-item-remove {
								right: 20px;
								top: 10px;
								width: 20px;
								height: 20px;
								font-size: 14px;
							}
						}
					}
				}
			}
			.pos-list-summary {
				padding: 10px 20px;

				.pos-list {
					.pos-list-box {
						.pos-list-item {
							span {
								font-size: 20px;
								line-height: normal;
							}
							.pos-list-item-total-text {
								font-size: 20px;
								font-weight: 900;
							}
						}
					}
				}
			}
			.pos-payment-details {
				margin: 5px;
				background-color: white;

				padding: 10px;
				box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
				h6 {
					color: rgb(59, 59, 59);
					font-size: 17px;
					text-transform: capitalize;
				}
				.payment-list {
					display: flex;
					align-items: center;
					gap: 10px;
					justify-content: space-between;

					.payment-list-box {
						position: relative;
						width: 40%;
						&::after {
							position: absolute;
							content: '';
							width: 1px;
							height: 100%;
							top: 0;
							right: -25%;
							background-color: rgb(206, 204, 204);
						}
						&:last-child {
							&:after {
								display: none;
							}
						}
						.payment-list-item {
							display: flex;
							align-items: center;
							justify-content: space-between;
							position: relative;
							.payment-list-item-remove {
								padding: 5px;
								position: absolute;
								right: -5px;
								color: rgb(247, 78, 78);
								font-size: 12px;
								font-weight: 700;
								cursor: pointer;
							}
							span {
								font-size: 12px;
								font-weight: 600;
								color: rgb(95, 94, 94);
								padding-right: 30px;
							}
						}
					}
				}
			}
			.pos-details-print {
				background-color: white;

				padding: 10px;
				.pos-button-wrapper {
					.pos-button {
						@mixin common-button-print {
							width: 200px;
							font-size: 18px;
							padding: 12px 0;
						}
						.close-button {
							@include common-button-print;
							background-color: rgb(245, 225, 225);
							color: red;
							border: 1px solid red;
						}
						.hold-button {
							@include common-button-print;
							background-color: rgb(75, 177, 218);
							color: white;
							border: 1px solid white;
						}
						.unhold-button {
							@include common-button-print;
							background-color: rgb(75, 177, 218);
							color: white;
							border: 1px solid white;
						}
						.reset-button {
							@include common-button-print;
							background-color: red;
							color: white;
						}
						.print-button {
							@include common-button-print;
							background-color: $blue;
							color: white;
						}
					}
				}
			}
			.pos-calculator-section {
				.calc-wrapper {
					input[type='text'] {
						height: 50px;
						font-size: 26px;

						font-weight: 700;
					}

					button {
						font-size: 20px;
					}

					.keypad {
						grid-auto-rows: minmax(50px, auto);
					}
				}
			}
			.pos-payment-section {
				button {
					font-size: 22px;
					font-weight: 700;
				}

				.content h6 {
					color: black;
					margin-top: 10px;
					font-size: 18px;
				}
			}
			.pos-payment-final-details {
				.payment-details-card-wrapper {
					.payment-details-card {
						span {
							font-size: 20px;
						}
						p {
							font-size: 18px;
							font-weight: 800;
						}
					}
				}
			}
		}
	}
	.posSummary {
		.posSummaryCol {
			margin-right: 20px;

			h6 {
				font-size: 18px;
			}
		}
	}
}
/* Full HD - TV Layout: 2560px. */
@media only screen and (min-width: 2560px) and (max-width: 4000px) {
}
/* Full HD - Small TV Layout: 2400px. */
@media only screen and (min-width: 2200px) and (max-width: 2559px) {
}
/* HD Layout: 1920px. */
@media only screen and (min-width: 1601px) and (max-width: 2200px) {
}
/* Laptop with HDPI: 1440px */
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
}
/* Normal PC: 1366px */
@media only screen and (min-width: 1301px) and (max-width: 1399px) {
}
/* Laptop with MDPI: 1280px */
@media only screen and (min-width: 1201px) and (max-width: 1300px) {
}
/* Medium Layout(Vertical): 1080px. */
@media only screen and (max-width: 1200px) {
}
/* Medium Layout(iPad Pro): 1024px. */
@media only screen and (max-width: 1050px) {
	.ant-radio-group {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		// flex-wrap: wrap;
		overflow-x: scroll;
		padding-bottom: 20px;

		&::-webkit-scrollbar {
			display: block;
			height: 0px;
		}
	}
}
/* Tablet Layout: 768px. */
@media only screen and (max-width: 991px) {
}
/* Mobile Layout: 375px. */
@media only screen and (max-width: 768px) {
  
}
/* Wide Mobile Layout(Nexus 7): 600px. */
@media only screen and (max-width: 610px) {
}
/* Wide Mobile Layout: 480px. */
@media only screen and (max-width: 500px) {
}
/* Mobile Layout(Samsung Galaxy S5): 360px. */
@media only screen and (max-width: 370px) {
}
/* Mobile Layout(iPhone 4): 320px. */
@media only screen and (max-width: 330px) {
}


.sponsor_section_wrapper {
	margin: 100px 0;
	.top_arrow_slider {
		.swiper {
			margin: 60px 0;
			.slide_item_wrapper {
				.slide_image_wrapper {
					position: relative;
					width: 100%;
					height: 60px;
					cursor: pointer;
					&:hover {
						.slide_image {
							transition: all 0.3s;
							filter: none;
						}
					}
					.slide_image {
						transition: all 0.5s;
						filter: opacity(35%) grayscale(100%);
					}
				}
			}
		}
	}
}

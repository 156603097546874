.blog_top_section {
	padding: 50px 0px;
	height: 65vh;
	position: relative;
	background-image: url('/images/page/blog/blog-bg.png');
	background-repeat: no-repeat;
	z-index: 1;
	background-position: center;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	&::after {
		position: absolute;
		content: '';
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: -1;
		background: #325aab94;
	}
	.section_title {
		max-width: 700px;
		text-align: center;
		margin: auto;
		font-size: 74px;
		color: white;
		font-weight: 600;
	}
}

.blog_section_wrapper {
	background-color: white;
	padding: 40px 0;

	.blog_title_start {
		margin: 0;
		font-size: 48px;
		font-weight: 600;
		color: $home_title_color;
	}
	.blog_title {
		margin: 0;
		text-align: center;
		font-size: 48px;
		font-weight: 600;
		color: $home_title_color;
	}

	.blog_content_wrapper {
		// featured post section start
		.featured_post_section_wrapper {
			margin-top: 40px;
			.featured_post_content_wrapper {
				display: flex;
				gap: 10px;
				margin-top: 10px;
				border-radius: 15px;
				overflow: hidden;
				box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
				cursor: pointer;

				&:hover {
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				}

				.featured_post_image {
					position: relative;
					min-width: 400px;
					max-width: 400px;
					width: 100%;
					height: 300px;

					overflow: hidden;

					img {
						object-fit: cover;
						object-position: top center;
					}
				}
				.featured_post_content {
					// padding: 0 0 0 20px;
					padding: 10px 20px 10px 10px;
					// text-align: justify;
					.post_title {
						font-size: 32px;
						font-weight: 500;
						cursor: pointer;
					}
					.post_description {
						font-size: 16px;
						color: $home_paragraph_color;
		
						text-align: justify;
					}
				}
			}
		}
		// most read post section start
		.most_read_section_wrapper {
			margin-top: 30px;
			.blog_card_item_wrapper {
				margin-top: 60px;
				display: grid;
				grid-template-columns: auto auto auto;
				grid-row-gap: 50px;

				.blog_card {
					box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

					max-width: 380px;
					min-height: 485px;
					width: 100%;
					position: relative;
					border-radius: 15px;
					overflow: hidden;
					background-color: white;

					&:hover {
						box-shadow: rgba(78, 78, 78, 0.1) 0px 12px 12px;
					}
					.blog_card_image_wrapper {
						position: relative;
						width: 100%;
						height: 100%;
						min-height: 250px;
						max-height: 250px;
						z-index: 1;
						&::after {
							position: absolute;
							content: '';

							bottom: -30px;
							width: 100%;
							height: 50px;
							z-index: 1;
							background-image: linear-gradient(
								to bottom,
								rgba(0, 0, 0, 0) 20%,
								rgba(0, 0, 0, 0.151) 50%,
								rgba(255, 255, 255, 0.247) 100%
							);
							backdrop-filter: blur(1px);
						}
						img {
							object-fit: cover;
							object-position: top center;
						}
					}

					.blog_info {
						padding: 0 10px;
						margin-top: 30px;
						.blog_info_title {
							font-size: 17px;
							font-weight: 600;
							text-transform: capitalize;
							overflow: hidden;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							-webkit-transition: ease-in-out all 0.5s;
							transition: ease-in-out all 0.5s;
							cursor: pointer;
						}
						.blog_info_des {
							font-size: 14px;
							font-weight: 400;
							color: $home_paragraph_color;

							overflow: hidden;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							-webkit-transition: ease-in-out all 0.5s;
							transition: ease-in-out all 0.5s;
						}
						.read_button_wrapper {
							margin-top: 10px;
							cursor: pointer;
							.read_button {
								background-image: $home_gradient_primary_button;
								cursor: pointer;
								text-align: center;
								text-transform: capitalize;
								transition: 0.5s;
								background-size: 200% auto;
								color: white;
								border-radius: 8px;
								// display: block;
								font-size: 12px;
								font-weight: 400;
								width: 100px;
								height: 30px;
								&:hover {
									background-position: right center;
									color: #fff;
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.blog_top_section {
		padding: 20px 0px;
		height: 40vh;

		.section_title {
			font-size: 38px;
		}
	}

	.blog_section_wrapper {
		background-color: white;
		padding: 20px 0;

		.blog_title_start {
			font-size: 28px;
		}
		.blog_title {
			font-size: 28px;
		}

		.blog_content_wrapper {
			// featured post section start
			.featured_post_section_wrapper {
				margin-top: 20px;
				.featured_post_content_wrapper {
					display: flex;
					flex-direction: column;
					gap: 10px;

					.featured_post_image {
						min-width: 100%;
						height: 180px;
						position: relative;
						&::after {
							position: absolute;
							content: '';

							bottom: -30px;
							width: 100%;
							height: 50px;
							z-index: 1;
							background-image: linear-gradient(
								to bottom,
								rgba(0, 0, 0, 0) 20%,
								rgba(0, 0, 0, 0.151) 50%,
								rgba(255, 255, 255, 0.247) 100%
							);
							backdrop-filter: blur(1px);
						}

						img {
							object-fit: cover;
							object-position: top center;
						}
					}
					.featured_post_content {
						padding: 10px;
						.post_title {
							font-size: 16px;
							line-height: normal;
						}
						.post_description {
							font-size: 12px;
							color: $home_paragraph_color;

							text-align: justify;
						}
					}
				}
			}
			// most read post section start
			.most_read_section_wrapper {
				margin-top: 10px;
				.blog_card_item_wrapper {
					margin-top: 40px;
					display: grid;
					grid-template-columns: auto;
					grid-row-gap: 20px;

					.blog_card {
						min-height: max-content;
						max-width: 100%;
						padding-bottom: 20px;
						.blog_card_image_wrapper {
							position: relative;
							width: 100%;
							height: 100%;
							min-height: 180px;
							max-height: 180px;
							z-index: 1;
							&::after {
								position: absolute;
								content: '';

								bottom: -30px;
								width: 100%;
								height: 50px;
								z-index: 1;
								background-image: linear-gradient(
									to bottom,
									rgba(0, 0, 0, 0) 20%,
									rgba(0, 0, 0, 0.151) 50%,
									rgba(255, 255, 255, 0.247) 100%
								);
								backdrop-filter: blur(1px);
							}
							img {
								object-fit: cover;
								object-position: top center;
							}
						}

						.blog_info {
							padding: 0 10px;
							margin-top: 30px;
							.blog_info_title {
								font-size: 16px;
								font-weight: 600;
								text-transform: capitalize;
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 3;
								-webkit-box-orient: vertical;
								-webkit-transition: ease-in-out all 0.5s;
								transition: ease-in-out all 0.5s;
							}
							.blog_info_des {
								font-size: 12px;
								font-weight: 400;
								color: $home_paragraph_color;

								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 3;
								-webkit-box-orient: vertical;
								-webkit-transition: ease-in-out all 0.5s;
								transition: ease-in-out all 0.5s;
							}
							.read_button_wrapper {
								margin-top: 10px;
								cursor: pointer;
								.read_button {
									font-size: 11px;
									width: 100px;
									height: 28px;
									&:hover {
										background-position: right center;
										color: #fff;
										text-decoration: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.blog_top_container {
	max-width: 950px;
	margin: auto;
	padding: 10px;
}
.blog_detials_container {
	max-width: 900px;
	margin: auto;
	text-align: justify;
	padding: 10px;
}
.blog_details_top_wrapper {
	background: rgb(250, 246, 246) !important;
	padding: 30px;

	.blog_deatils_top {
		.blog_title {
			text-transform: capitalize;
			font-size: 42px;
			text-align: center;
			font-weight: 600;
			color: rgb(53, 52, 52);
			margin-top: 20px;
		}
		.blog_author_info {
			margin: 50px 0 20px 0;

			display: flex;
			align-items: center;
			justify-content: space-between;

			.info_item_wrapper {
				display: flex;
				flex-direction: column;
				position: relative;
				min-width: 200px;
				padding: 10px 0;
				&::after {
					position: absolute;
					content: '';
					top: 0;
					left: 0;
					width: 100%;
					height: 1px;
					background-color: gray;
				}
				.posted_title {
					margin: 0;
				}
				.posted_name {
					margin: 0;
					font-weight: 600;
					text-transform: capitalize;
				}
			}
		}
	}
	.blog_image_wrapper {
		margin-top: 20px;
		position: relative;
		width: 100%;
		height: 550px;
		border-radius: 5px;
		overflow: hidden;

		img {
			object-fit: cover;
			object-position: center;
		}
	}
}

.blog_detials_container {
	h1 {
		font-size: 34px;
		text-align: left;
		font-weight: 600;
	}
	p {
		font-size: 16px;
		color: $home_paragraph_color;
		line-height: normal;
	}
}

@media only screen and (max-width: 768px) {
	.blog_top_container {
		max-width: 100%;

		padding: 10px;
	}
	.blog_detials_container {
		max-width: 100%;

		padding: 10px;
	}

	.blog_details_top_wrapper {
		padding: 0px;
		.blog_deatils_top {
			padding: 5px;
			.blog_title {
				text-transform: capitalize;
				font-size: 22px;
				text-align: center;
			}
			.blog_author_info {
				margin: 30px 0;

				display: flex;
				align-items: center;
				justify-content: space-between;

				.info_item_wrapper {
					min-width: 120px;
					padding: 5px 0;

					.posted_title {
						font-size: 12px;
					}
					.posted_name {
						font-size: 12px;
					}
				}
			}
		}
		.blog_image_wrapper {
			margin-top: 20px;
			position: relative;
			width: 100%;
			height: 350px;
			border-radius: 5px;
			overflow: hidden;

			img {
				object-fit: cover;
				object-position: center;
			}
		}
	}

	.blog_detials_container {
		h1 {
			font-size: 18px;
			text-align: left;
		}
		p {
			font-size: 12px;
		}
	}
}

.dashboard-sidebar-section {
	height: 100%;
	overflow-y: auto;
	padding-bottom: 25px;

	&::-webkit-scrollbar {
		width: 0px;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #cecccc;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background-color: #333;
	}
	&::-webkit-scrollbar-track-piece {
		margin-top: 30px;
	}

	.dashboard-sidebar-footer {
		padding: 5px;
		position: absolute;
		bottom: 0;
		width: 100%;
		text-align: center;
		color: black;
		background-image: $gradient-primary;
		color: white;
		font-size: 11px;
		z-index: 100;
	}
	.dashboard-sidebar-wrapper {
		padding-bottom: 10px;
		text-transform: capitalize;
		.menu-item-title {
			padding: 0px 15px;
			display: block;
			text-align: center;
			background-color: #f5f2f2;
			font-size: 12px;
			font-weight: 600;
			text-transform: uppercase;
		}

		ul {
			color: black !important;

			.menu-item-group-title {
				padding: 4px 15px;
				display: block;
				font-size: 12px;
				font-weight: 900;
				text-transform: uppercase;
				background-color: #f5f2f2;
				color: $secondary-color;
			}
			.menu-item {
				padding: 3px 15px;
				cursor: pointer;
				font-size: 12px;
				font-weight: 500;
				display: flex;
				align-items: center;
				gap: 10px;
				position: relative;
				&::after {
					position: absolute;
					content: '';
					width: 100%;
					height: 1px;
					background-color: #f0ecec;
					left: 0;
					bottom: 0;
				}

				&:hover {
					background-color: #819ee2;
					color: white;
				}
				.menu-item-icon {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.menu-item-icon-collapsed {
					padding: 5px 0;
				}
			}
			& .active {
				background-image: $gradient-primary;
				color: white;
			}
		}
	}
}
